import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeDialog } from "../../../redux/slice/dialogueSlice";
import { DangerRight } from "../../api/toastServices";
import { ExInput } from "../../extras/Input";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import Button from "../../extras/Button";
import {
  getSalonSchedule,
  updateSalonTime
} from "../../../redux/slice/salonSlice";
import { SuccessStatusCode } from "../../../util/constants";

const ScheduleDialogue = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [error, setError] = useState({
    openTime: "",
    closeTime: ""
  });

  useEffect(() => {
    if (dialogueData) {
      console.log("dialogueData", dialogueData);
      setOpenTime(
        moment(dialogueData?.data?.openAt, "hh:mm").utc(0).format("hh:mm")
      );
      setCloseTime(
        moment(dialogueData?.data?.closeAt, "hh:mm").utc(0).format("hh:mm")
      );
    }
  }, [dialogueData]);

  console.log("openTime", openTime);
  console.log("closeTime", closeTime);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!openTime || !closeTime) {
      let error = {};
      if (!openTime) error.openTime = "Open time is required";
      if (!closeTime) error.closeTime = "Close time is required";
      return setError({ ...error });
    } else {
      console.log("openTime", openTime);
      console.log("closeTime", closeTime);
      let body = {
        operationHours: [
          {
            openAt: openTime,
            closeAt: closeTime,
            day: dialogueData?.data?.day
          }
        ]
      };
      console.log("body", body);
      // return;
      let response = null;
      if (dialogueData) {
        const payload = {
          data: body,
          id: dialogueData?.salonId
        };
        response = await dispatch(updateSalonTime(payload));
      }
      if (response?.payload?.statusCode === SuccessStatusCode) {
        dispatch(closeDialog());
        dispatch(getSalonSchedule(dialogueData?.salonId));
      } else {
        DangerRight(response.message);
      }
    }
    dispatch(closeDialog());
  };

  function handleOpenTime(v) {
    setOpenTime(
      v.hour().toString().padStart(2, "0") +
        ":" +
        v.minute().toString().padStart(2, "0")
    );
  }

  function handleCloseTime(v) {
    setCloseTime(
      v.hour().toString().padStart(2, "0") +
        ":" +
        v.minute().toString().padStart(2, "0")
    );
  }

  useEffect(() => {
    const timePicker = document.querySelector(".rc-time-picker");
    if (timePicker) {
      timePicker.classList.remove("rc-time-picker");
    }
  }, []);

  return (
    <div className="dialog">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-md-8 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">월요일 영업 시간 설정</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="expertForm">
                <div className="row align-items-start formBody">
                  <div className="col-12 col-md-6">
                    {/* {JSON.stringify(dialogueData?.data)} */}
                    <div className="inputData text  flex-row justify-content-start text-start">
                      <label className="col-12 m-0">영업 시작 시간</label>
                      <TimePicker
                        // value={openTime}
                        onChange={(e) => handleOpenTime(e)}
                        showSecond={false}
                        allowEmpty
                        use12Hours
                        defaultValue={
                          dialogueData && dialogueData?.data?.openAt
                            ? moment(dialogueData?.data?.openAt, "hh:mm")
                            : null
                        }
                      />
                      {error && error?.openTime && (
                        <p className="errorMessage text-start">
                          {error && error?.openTime}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6 inputData">
                    {/* {JSON.stringify(
                      moment(dialogueData?.data?.close, "hh:mm a")
                    )} */}
                    <label className="col-12 m-0">영업 종료 시간</label>
                    <TimePicker
                      onChange={(e) => handleCloseTime(e)}
                      showSecond={false}
                      allowEmpty
                      use12Hours
                      defaultValue={
                        dialogueData && dialogueData?.data?.closeAt
                          ? moment(dialogueData?.data?.closeAt, "hh:mm")
                          : null
                      }
                    />
                    {error && error?.closeTime && (
                      <p className="errorMessage text-start">
                        {error && error?.closeTime}
                      </p>
                    )}
                  </div>
                </div>
                <div className="row  formFooter">
                  <div className="col-12 text-end m0">
                    <Button
                      className={`bg-gray text-light`}
                      text={`취소`}
                      type={`button`}
                      onClick={() => dispatch(closeDialog())}
                    />
                    <Button
                      type={`submit`}
                      d
                      className={` text-white m10-left`}
                      style={{ backgroundColor: "#1ebc1e" }}
                      text={`등록`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleDialogue;

function tConvert(time) {
  // Check correct time format and split into components
  const formatTime = time
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:([0-5]\d))?$/) || [time];

  if (formatTime.length > 1) {
    const timeParts = formatTime.slice(1);
    const period = +timeParts[0] < 12 ? " AM" : " PM";
    const hours = +timeParts[0] % 12 || 12; // Adjust hours
    const minutes = timeParts[2].padStart(2, "0");

    return hours + ":" + minutes + period;
  }

  return time;
}
