import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slice/authSlice";
import expertSlice from "./slice/expertSlice";
import dialogueSlice from "./slice/dialogueSlice";
import categorySlice from "./slice/categorySlice";
import serviceSlice from "./slice/serviceSlice";
import reviewSlice from "./slice/reviewSlice";
import userSlice from "./slice/userSlice";
import settingSlice from "./slice/settingSlice";
import timeSlice from "./slice/timeSlice";
import bookingSlice from "./slice/bookingSlice";
import payoutSlice from "./slice/payoutSlice";
import dashSlice from "./slice/dashSlice";
import spinnerReducer from "./slice/loading.reducer";
import salarySlice from "./slice/salarySlice";
import attendanceSlice from "./slice/attendanceSlice";
import complainSlice from "./slice/complainSlice";
import notificationSlice from "./slice/notificationSlice";
import holidaySlice from "./slice/holidaySlice";
import salonSlice from "./slice/salonSlice";
import faqSlice from "./slice/faqSlice";
import noticeSlice from "./slice/noticeSlice";
import csSlice from "./slice/csSlice";
import couponSlice from "./slice/couponSlice";
import hashTagSlice from "./slice/hashTagSlice";
import adminSlice from "./slice/adminSlice";
import leaderSlice from "./slice/leaderSlice";
import permissionSlice from "./slice/permissionSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    expert: expertSlice,
    dialogue: dialogueSlice,
    category: categorySlice,
    service: serviceSlice,
    review: reviewSlice,
    user: userSlice,
    setting: settingSlice,
    time: timeSlice,
    booking: bookingSlice,
    payout: payoutSlice,
    dash: dashSlice,
    spinner: spinnerReducer,
    salary: salarySlice,
    attendance: attendanceSlice,
    complain: complainSlice,
    notification: notificationSlice,
    holiday: holidaySlice,
    salon: salonSlice,
    faq: faqSlice,
    notice: noticeSlice,
    cs: csSlice,
    coupon: couponSlice,
    hashTag: hashTagSlice,
    admin: adminSlice,
    leader: leaderSlice,
    permission: permissionSlice
  }
  // devTools:false
});

export default store;
