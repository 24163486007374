import React, { useEffect, useMemo, useRef, useState } from "react";
import { closeDialog } from "../../redux/slice/dialogueSlice";
import Table from "../extras/Table";
import { useDispatch, useSelector } from "react-redux";
import Button from "../extras/Button";
import { SuccessStatusCode } from "../../util/constants";
import { toast } from "react-toastify";
import {
  settlementApproveBluk,
  settlementCompleteBluk
} from "../../redux/slice/salonSlice";

const mapStatusToKo = {
  pending: "미승인",
  rejected: "거절",
  approved: "승인",
  completed: "완료"
};

export default function BatchModal(props) {
  const loadingRef = useRef(false);
  const { title, subTitle } = props;
  const { dialogueData, dialogueType } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(-1);
  const [page, setPage] = useState(0);

  const [selected, setSelected] = React.useState([]);

  useEffect(() => {
    if (dialogueData) {
      setData(dialogueData);
    }
  }, [dialogueData]);

  function handleSelect(e, id) {
    if (e.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((item) => item !== id));
    }
  }

  function handleSelectAll(e) {
    if (e.target.checked) {
      setSelected(data.map((item) => item.id)); // select all
    } else {
      // if false
      setSelected([]); // unselect all
    }
  }

  const mapData = [
    {
      Header: (
        <div className="d-flex align-items-center justify-content-center">
          <input
            type="checkbox"
            style={{ height: "17px", width: "17px" }}
            checked={selected.length === dialogueData.length}
            onChange={handleSelectAll}
          />
        </div>
      ),
      Cell: ({ row }) => (
        <div className="d-flex align-items-center justify-content-center">
          <input
            type="checkbox"
            style={{ height: "17px", width: "17px" }}
            checked={selected.includes(row?.id)}
            onChange={(e) => handleSelect(e, row?.id)}
          />
        </div>
      )
    },
    {
      Header: "No",
      Cell: ({ index }) => <span>{parseInt(index) + 1}</span>
    },
    {
      Header: "이름",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          style={{ backgroundColor: "#fff" }}
          // onClick={() => handleInfo(row?._id)}
        >
          {row?.applicant?.nickname}
        </button>
      )
    },
    {
      Header: `전화번호`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.applicant?.phoneNumber}</span>
      )
    },
    {
      Header: `입금 계좌`,
      Cell: ({ row }) => (
        <div className="text-center d-flex flex-column">
          <span className="text-capitalize">{row?.depositAccountName}</span>
          <span className="text-capitalize">{row?.depositAccountNumber}</span>
        </div>
      )
    },

    {
      Header: "총 정산 대상 금액",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.settlementTargetAmount}</span>
      )
    },
    {
      Header: `총 정산금액`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.settlementAmount}</span>
      )
    },
    {
      Header: `정산 여부`,
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.status ? mapStatusToKo[row?.status] : ""}
        </span>
      )
    }
  ];

  const handleSubmit = async () => {
    try {
      if (!selected.length) {
        loadingRef.current = false;
        toast.warn("Please select ");
        return;
      } else {
        const body = {
          ids: selected
        };

        let res = null;
        if (dialogueType === "batchApprove") {
          res = await dispatch(settlementApproveBluk(body));
        } else {
          res = await dispatch(settlementCompleteBluk(body));
        }
        if (res.payload?.statusCode === SuccessStatusCode) {
          setSelected([]);
          setData([]);
          dispatch(closeDialog());
          props.refetch && props.refetch();
        }
      }
    } catch (err) {
      loadingRef.current = false;
      console.log("err.message", err);
    }
    loadingRef.current = false;
  };

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="mainDiaogBox position-relative">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">{title}</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <p className="mb-4">{subTitle}</p>
                <p className="mb-1 titleTable">승인 처리 대상 금액</p>
                <div className="col-12">
                  <Table
                    data={data}
                    mapData={mapData}
                    classWrapper={"mainTable mainTableModal"}
                    PerPage={-1}
                    Page={page}
                    type={"client"}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center gap-4">
                  <Button
                    className={`bg-gray text-light`}
                    text={`취소`}
                    type={`button`}
                    onClick={() => dispatch(closeDialog())}
                  />
                  <Button
                    type={`submit`}
                    className={` text-white m10-left`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`선택 승인`}
                    disabled={loadingRef.current}
                    onClick={() => handleSubmit()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
