import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ExInput } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import { getAllStoresList } from "../../../redux/slice/salonSlice";
import {
  addPermission,
  updatePermission
} from "../../../redux/slice/permissionSlice";
import { useRef } from "react";

const listMenu = [
  {
    id: "storeManagement",
    label: "매장관리",
    checked: false
  },
  {
    id: "reservationManagement",
    label: "예약 리스트",
    checked: false
  },
  {
    id: "memberManagement",
    label: "회원 관리",
    checked: false
  },
  {
    id: "settlementManagement",
    label: "정산 관리",
    checked: false
  },
  {
    id: "announcementManagement",
    label: "공지사항 설정",
    checked: false
  },
  {
    id: "adminManagement",
    label: "관리자 등록",
    checked: false
  },
  {
    id: "otherAppManagement",
    label: "기타 앱 수정",
    checked: false
  }
];

const AddPermissionModal = (props) => {
  const dispatch = useDispatch();
  const loadingRef = useRef(false);
  const { dialogueData, dialogueType } = useSelector((state) => state.dialogue);
  const [stores, setStores] = useState([]);
  const [type, setType] = useState("");
  const [error, setError] = useState({
    type: ""
  });

  const [listCheckbox, setListCheckbox] = useState(listMenu);
  const [storeSelected, setStoreSelected] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const res = await dispatch(getAllStoresList({}));
      setStores(res.payload?.data);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch]);

  useEffect(() => {
    if (dialogueType === "addPermission") {
      fetchData();
    }
  }, [fetchData, dialogueType]);

  useEffect(() => {
    if (dialogueData) {
      setType(dialogueData.category);
      setStoreSelected(dialogueData.storeList?.map((item) => item.id));
      setListCheckbox([
        {
          id: "storeManagement",
          label: "매장관리",
          checked: dialogueData.storeManagement
        },
        {
          id: "reservationManagement",
          label: "예약 리스트",
          checked: dialogueData.reservationManagement
        },
        {
          id: "memberManagement",
          label: "회원 관리",
          checked: dialogueData.memberManagement
        },
        {
          id: "settlementManagement",
          label: "정산 관리",
          checked: dialogueData.settlementManagement
        },
        {
          id: "announcementManagement",
          label: "공지사항 설정",
          checked: dialogueData.announcementManagement
        },
        {
          id: "adminManagement",
          label: "관리자 등록",
          checked: dialogueData.adminManagement
        },
        {
          id: "otherAppManagement",
          label: "기타 앱 수정",
          checked: dialogueData.otherAppManagement
        }
      ]);
    }
  }, [dialogueData]);

  const storeList = useMemo(() => {
    return stores.map((item) => ({
      id: item.storeId,
      name: item.storeName
    }));
  }, [stores]);

  const handleSelect = (value) => {
    if (storeSelected.includes(value)) {
      const newSelected = [...storeSelected].filter((item) => item !== value);
      setStoreSelected(newSelected);
    } else {
      setStoreSelected([...storeSelected, value]);
    }
  };

  const handleCheck = (id) => {
    const newListCheckbox = [...listCheckbox];
    const itemIndex = listCheckbox.findIndex((item) => item.id === id);
    newListCheckbox[itemIndex] = {
      ...newListCheckbox[itemIndex],
      checked: !newListCheckbox[itemIndex].checked
    };
    setListCheckbox(newListCheckbox);
  };

  const handleRemoveStore = (value) => {
    const newStoreSelected = [...storeSelected].filter(
      (item) => item !== value
    );
    setStoreSelected(newStoreSelected);
  };

  const handleResetStore = () => {
    setStoreSelected([]);
  };
  const handleSelectAll = () => {
    setStoreSelected(storeList.map((item) => item.id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!type) {
        let error = {};
        if (!type) error.type = "Type is Required";

        return setError({ ...error });
      } else {
        loadingRef.current = true;
        const result = {};
        listCheckbox.forEach((item) => {
          result[item.id] = item.checked;
        });
        const body = {
          category: type,
          ...result,
          storeIds: result.storeManagement ? storeSelected : []
        };
        let res = null;
        if (dialogueData?.id) {
          res = await dispatch(updatePermission({ id: dialogueData.id, body }));
        } else {
          res = await dispatch(addPermission(body));
        }
        console.log("res", res, res?.payload);
        if (res?.payload?.data?.data) {
          dispatch(closeDialog());
          props.refetch && props.refetch();
        }
        loadingRef.current = false;
      }
    } catch (e) {
      console.log(e);
      loadingRef.current = false;
    }
  };

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-md-7 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">권한 추가</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div
                className="row align-items-start formBody"
                style={{ maxHeight: "calc(100svh - 200px)" }}
              >
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"분류"}
                    value={type}
                    errorMessage={error.type}
                    onChange={(e) => {
                      setType(e.target.value);
                      if (e.target.value) {
                        setError({
                          type: ""
                        });
                      } else {
                        setError({
                          type: "Type is required"
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 inputData">
                  <label>접근 메뉴</label>
                  <div className="d-flex gap-4 flex-wrap mt-2">
                    {listCheckbox.map((item) => (
                      <div
                        key={item.id}
                        className="d-flex flex-row-reverse align-items-center gap-2"
                      >
                        <label htmlFor={item.id} className="text-nowrap">
                          {item.label}
                        </label>
                        <input
                          id={item.id}
                          type="checkbox"
                          style={{ height: "17px", width: "17px" }}
                          checked={item.checked}
                          onClick={() => handleCheck(item.id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                {listCheckbox[0].checked && (
                  <div className="col-12">
                    <div className="inputData d-flex align-items-center justify-content-between">
                      <label>관리 매장 선택</label>
                      <Button
                        className={`text-black m10-left `}
                        style={{
                          backgroundColor: "#E7E6E6"
                        }}
                        text={`전체 선택`}
                        onClick={handleSelectAll}
                      />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div
                          className="row align-items-start formBody px-2"
                          style={{
                            columnGap: "20px"
                          }}
                        >
                          {storeList.map((item) => (
                            <div
                              className={`border rounded col-4 d-flex align-items-center cursor-pointer px-20 py-0 ${
                                storeSelected.includes(item.id)
                                  ? "bg-gray"
                                  : "bg-white"
                              }`}
                              style={{
                                height: "40px",
                                flexBasis: "calc(100%/3 - 20px)"
                              }}
                              key={item}
                              onClick={() => handleSelect(item.id)}
                            >
                              {item.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="row flex-grow-1 align-items-start formBody px-2">
                            {stores.length > 0 &&
                              storeSelected.map((item) => (
                                <div
                                  className={`border bg-gray text-nowrap rounded d-flex align-items-center px-20 py-0`}
                                  style={{
                                    height: "40px",
                                    maxWidth: "fit-content"
                                  }}
                                  key={item}
                                >
                                  {item
                                    ? storeList.find(
                                        (store) => store.id === item
                                      )?.name
                                    : ""}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="cursor-pointer"
                                    style={{
                                      width: "24px",
                                      height: "24px"
                                    }}
                                    onClick={() => handleRemoveStore(item)}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </div>
                              ))}
                          </div>
                          <Button
                            className={`text-black m10-left `}
                            style={{
                              backgroundColor: "#E7E6E6",
                              height: "40px",
                              width: "100px",
                              minWidth: "100px"
                            }}
                            text={`초기화`}
                            onClick={handleResetStore}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-12 d-flex justify-content-end align-items-center gap-2">
                <Button
                  type={`submit`}
                  className={`text-white m10-left`}
                  style={{
                    backgroundColor: "#E7E6E6"
                  }}
                  text={`취소`}
                  onClick={() => {
                    dispatch(closeDialog());
                  }}
                />
                <Button
                  type={`submit`}
                  className={`text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`저장`}
                  disabled={loadingRef.current}
                  onClick={(e) => handleSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPermissionModal;
