import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExInput } from "../../extras/Input";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import moment from "moment";

const CancleDetails = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  useEffect(() => {
    if (dialogueData.id) {
      setData(dialogueData);
    }
  }, [dialogueData]);

  return (
    <div className="dialog">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-md-6 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">취소 상세</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                      setData({});
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <form id="expertForm">
                <div className="row align-items-start formBody">
                  <div className="col-12">
                    <ExInput
                      type={`text`}
                      id={`reason`}
                      name={`reason`}
                      label={`취소자`}
                      value={data?.cancelInfo?.canceler?.nickname}
                      disabled={true}
                    />
                  </div>
                  <div className="col-12">
                    <ExInput
                      type={`text`}
                      id={`Time`}
                      name={`Time`}
                      label={`사유`}
                      placeholder={`Time`}
                      value={data?.cancelInfo?.reason}
                      disabled={true}
                    />
                  </div>
                  <div
                    className="col-12 d-flex"
                    style={{
                      columnGap: "20px"
                    }}
                  >
                    <div style={{ flexBasis: "calc(50% - 10px)" }}>
                      <ExInput
                        type={`text`}
                        id={`Date`}
                        name={`Date`}
                        label={`날짜`}
                        placeholder={`Date`}
                        value={
                          data?.cancelInfo?.cancelledAt
                            ? moment(data?.cancelInfo?.cancelledAt).format(
                                "YYYY-MM-DD"
                              )
                            : ""
                        }
                        disabled={true}
                      />
                    </div>
                    <div
                      className=" mt-auto"
                      style={{ flexBasis: "calc(50% - 10px)" }}
                    >
                      <ExInput
                        type={`text`}
                        id={`Date`}
                        name={`Date`}
                        label={` `}
                        placeholder={`Date`}
                        value={
                          data?.cancelInfo?.cancelledAt
                            ? moment(data?.cancelInfo?.cancelledAt).format(
                                "hh:mm a"
                              )
                            : ""
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CancleDetails;
