import React, { useEffect, useState } from "react";
import { ExInput, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { addCoupon, updateCoupon } from "../../../redux/slice/couponSlice";
import { SuccessStatusCode } from "../../../util/constants";
import { isNumber } from "../../../util/fuction";

const CouponAddModal = (props) => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [name, setName] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [content, setContent] = useState("");
  const [discount, setDiscount] = useState("");

  const [error, setError] = useState({
    name: "",
    date: "",
    content: "",
    discount: ""
  });

  useEffect(() => {
    if (dialogueData) {
      setName(dialogueData?.couponTitle);
      setContent(dialogueData?.description);
      setStart(dialogueData?.startAt ? new Date(dialogueData?.startAt) : "");
      setEnd(dialogueData?.endAt ? new Date(dialogueData?.endAt) : "");
      setDiscount(dialogueData?.discountRate);
    }
  }, [dialogueData]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!name || !content || !discount || !start || !end) {
        let error = {};
        if (!name) error.name = "Title is Required";
        if (!content) error.content = "Content is Required";
        if (!discount) error.discount = "Discount Rate is Required";
        if (!start) error.start = "Date is Required";
        if (!end) error.end = "Date is Required";

        setIsSubmitting(false);
        return setError({ ...error });
      } else {
        const body = {
          title: name,
          startAt: moment(start).format("YYYY-MM-DD"),
          endAt: moment(end).format("YYYY-MM-DD"),
          discountRate: discount,
          description: content,
          discountUnit: "PERCENT"
        };
        console.log("body", body);
        if (dialogueData?.couponId) {
          let payload = {
            body,
            id: dialogueData.couponId
          };
          const res = await dispatch(updateCoupon(payload));
          console.log("res.payload", res.payload);
          if (res.payload?.statusCode === SuccessStatusCode) {
            setIsSubmitting(false);
            dispatch(closeDialog());
            props.refetch && props.refetch();
          }
        } else {
          const res = await dispatch(addCoupon(body));
          console.log("res.payload", res.payload);
          if (res.payload?.statusCode === SuccessStatusCode) {
            setIsSubmitting(false);
            dispatch(closeDialog());
            props.refetch && props.refetch();
          }
        }
      }
    } catch (err) {
      setIsSubmitting(false);
      console.log("err.message", err);
    }
    setIsSubmitting(false);
  };

  const handleSetError = (field, value) => {
    let newError = {};
    if (field === "name") {
      newError.name = !value ? "Title is required" : "";
    }
    if (field === "discount") {
      newError.discount = !value ? "Discount is required" : "";
    }
    if (field === "content") {
      newError.content = !value ? "Content is required" : "";
    }
    setError((preState) => ({ ...error, ...newError }));
  };

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-4 col-md-6 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">쿠폰 등록</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"쿠폰명"}
                    value={name}
                    onChange={(e) => {
                      if (e.target.value.length <= 40) {
                        setName(e.target.value);
                      }
                      handleSetError("name", e.target.value);
                    }}
                    placeholder={"제목을 입력하세요. (공백 포함 40자 이내)"}
                  />
                </div>
                <div className="co-12 inputData">
                  <label>적용기간</label>
                  <div className=" d-flex align-items-center">
                    <ReactDatePicker
                      selected={start}
                      onChange={(value) => setStart(value)}
                      dateFormat="yyyy-MM-dd"
                      maxDate={end ? end : undefined}
                    />
                    <div
                      className="px-2"
                      style={{ height: "40px", marginTop: "7px" }}
                    >
                      -
                    </div>
                    <ReactDatePicker
                      selected={end}
                      onChange={(value) => setEnd(value)}
                      dateFormat="yyyy-MM-dd"
                      minDate={start ? start : undefined}
                    />
                  </div>
                  <p className="errorMessage text-capitalize">{error.date}</p>
                </div>

                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"할인율"}
                    value={discount}
                    errorMessage={error.discount}
                    onChange={(e) => {
                      if (
                        (isNumber(e.target.value) && e.target.value) ||
                        !e.target.value
                      ) {
                        setDiscount(e.target.value);
                        handleSetError("discount", e.target.value);
                      }
                    }}
                    placeholder={"할인율을 입력하세요 (%입력)"}
                  />
                </div>

                <div className="col-12">
                  <Textarea
                    value={content}
                    errorMessage={error.content}
                    classError={"errorMessage"}
                    onChange={(e) => {
                      if (e.target.value.length <= 200) {
                        setContent(e.target.value);
                      }
                      handleSetError("content", e.target.value);
                    }}
                    label={`쿠폰 내용`}
                    placeholder={`내용을 입력하세요. (공백 포함 200자 이내)`}
                  />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-end align-items-center gap-2">
                <Button
                  className={`bg-gray text-light`}
                  text={`취소`}
                  type={`button`}
                  onClick={() => dispatch(closeDialog())}
                />
                <Button
                  type={`submit`}
                  className={` text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`저장`}
                  disabled={isSubmitting}
                  onClick={() => handleSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponAddModal;
