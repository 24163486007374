/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import Button from "../../extras/Button";
import Table from "./Table";
import Pagination from "../../extras/Pagination";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Title from "../../extras/Title";
import { confirm, warning } from "../../../util/Alert";
import {
  getAllServices,
  deleteService,
  serviceStatus
} from "../../../redux/slice/serviceSlice";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServiceDialogue from "./ServiceDialogue";
import Searching from "../../extras/Searching";
import { useLocation } from "react-router-dom";
import { SideDishList } from "./SideDishList";
import SetMenuDescriptionModal from "./SetMenuDescriptionModal";
import { convertToTime } from "../../../util/fuction";

const mapTypeToKo = {
  SET: "세트",
  SINGLE: "단품"
};

const handleDataTable = (data = []) => {
  const rows = [];
  data.forEach((element, idx) => {
    const options = element.items || [];
    options.forEach((option, index) => {
      const { hours, minutes } = option.reservationAvailability
        ? convertToTime(option.reservationAvailability)
        : { hours: 0, minutes: 0 };
      if (index === 0) {
        rows.push({
          type: element.type,
          menuName: element.name,
          optionsName: option.name,
          optionsPrice: option.price,
          optionsPaymentAmount: option.settlementAmount,
          optionsReserverTime: option.reservationAvailability
            ? `${hours}시간 ${minutes ? minutes + "분" : ""}`
            : 0,
          optionsStock: option.stock,
          id: element.id,
          rowSpan: options.length,
          isRender: true,
          isDaytime: element.isDaytime,
          index: idx + 1
        });
      } else {
        rows.push({
          type: "",
          menuName: "",
          optionsName: option.name,
          optionsPrice: option.price,
          optionsPaymentAmount: option.settlementAmount,
          optionsReserverTime: option.reservationAvailability
            ? `${hours}시간 ${minutes ? minutes + "분" : ""}`
            : 0,
          optionsStock: option.stock,
          isDaytime: element.isDaytime,
          isRender: false
        });
      }
    });
  });
  return rows;
};

const MenuList = (props) => {
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const { service, total } = useSelector((state) => state.service);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("ALL");

  const payload = {
    start: page,
    limit: rowsPerPage,
    search
  };

  const fetchData = () => {
    dispatch(getAllServices(props.storeId));
  };

  useEffect(() => {
    fetchData();
  }, [props.storeId]);

  // useEffect(() => {
  //   setData(service);
  // }, [service]);

  useEffect(() => {
    setData(
      handleDataTable(
        service.filter((item) => item.type === "SET" || item.type === "SINGLE")
      )
    );
  }, [service]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  function handleOpenEdit(id) {
    const row = service.find((item) => item.id === id);
    dispatch(openDialog({ type: "service", data: row }));
  }

  const serviceTable = [
    {
      Header: "No",
      Cell: ({ row }) => <span>{row?.index}</span>,
      isMerger: true
    },
    {
      Header: "유형",
      isMerger: true,
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.type ? mapTypeToKo[row?.type] : ""}
        </span>
      )
    },
    {
      Header: "메뉴 이름",
      isMerger: true,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.menuName}</span>
      )
    },
    {
      tdClass: "colOpitonName",
      Header: "옵션 이름",
      Cell: ({ row }) => (
        <span
          className="text-capitalize"
          style={{
            minWidth: "4000px"
          }}
        >
          {row?.optionsName}
        </span>
      )
    },

    {
      Header: "가격",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.optionsPrice}원</span>
      )
    },
    {
      Header: "정산금액",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.optionsPaymentAmount}원</span>
      )
    },
    {
      Header: "예약 가능 시간",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.optionsReserverTime || "-"}
        </span>
      )
    },
    {
      Header: "재고",
      Cell: ({ row }) => (
        <span
          className={
            "text-capitalize" + row?.optionsStock && row?.optionsStock < 5
              ? "text-danger"
              : ""
          }
        >
          {row?.optionsStock} 개
        </span>
      )
    },
    {
      Header: "수정",
      isMerger: true,
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenEdit(row.id)}
        >
          수정
        </button>
      )
    },
    {
      Header: "삭제",
      isMerger: true,
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleDelete(row.id)}
        >
          삭제
        </button>
      )
    },
    {
      Header: "낮 시간",
      isMerger: true,
      Cell: ({ row }) => (
        <div>
          {row.isDaytime ? (
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#0c7fe9"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clipRule="evenodd"
              />
            </svg>
          ) : null}
        </div>
      )
    }
  ];

  const handleStatus = (id) => {
    dispatch(serviceStatus(id));
  };

  const handleDelete = (id) => {
    // const data = warning("Delete");
    const data = confirm({
      title: "메뉴 삭제",
      text: "해당 메뉴를 삭제하시겠습니까?",
      confirm: "확인",
      cancle: "취소"
    });
    data
      .then(async (logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          await dispatch(deleteService(id));
          setTimeout(() => {
            fetchData();
          }, 0);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="mainCategory">
      <div className="d-flex align-items-center gap-2">
        <Button
          className={`bg-button p-10 text-white m10-bottom `}
          // bIcon={`fa-solid fa-user-plus`}
          text="메뉴 추가"
          onClick={() => {
            dispatch(openDialog({ type: "service" }));
          }}
        />
        <Button
          className={`bg-warning p-10 text-white m10-bottom `}
          // bIcon={`fa-solid fa-user-plus`}
          text="메뉴 설명"
          onClick={() => {
            dispatch(openDialog({ type: "menuDescription" }));
          }}
        />
      </div>

      <div>
        <Table
          data={data}
          mapData={serviceTable}
          serverPerPage={rowsPerPage}
          classWrapper={"mainTable tableServices"}
          Page={page}
        />
      </div>
      <div className="mt-4">
        <SideDishList
          storeId={props.storeId}
          service={service.filter((item) => item.type === "SIDE_DISH")}
          fetchData={fetchData}
        />
      </div>
      {dialogue && dialogueType === "service" && (
        <ServiceDialogue
          setData={setData}
          data={data}
          storeId={props.storeId}
          refecth={fetchData}
        />
      )}
      {dialogue && dialogueType === "menuDescription" && (
        <SetMenuDescriptionModal setData={setData} data={data} />
      )}
    </div>
  );
};

export default MenuList;
