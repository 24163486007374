import React, { useEffect, useMemo, useState } from "react";
import { closeDialog } from "../../redux/slice/dialogueSlice";
import Table from "../extras/Table";
import { useDispatch, useSelector } from "react-redux";
import Button from "../extras/Button";

const mapStatusToKo = {
  pending: "미승인",
  rejected: "거절",
  approved: "승인",
  completed: "완료"
};

const typeSearch = [
  // {
  //   value: "",
  //   text: "전체"
  // },
  {
    value: "pending",
    text: "미승인"
  },
  {
    value: "rejected",
    text: "거절"
  },
  {
    value: "approved",
    text: "승인"
  },
  {
    value: "completed",
    text: "완료"
  }
];

function mergeItems(items) {
  // Sử dụng đối tượng để lưu trữ kết quả gộp
  const merged = {};

  items.forEach((item) => {
    const key = `${item.applicant.id}-${item.status}`; // Tạo khóa duy nhất cho mỗi (applicant.id, status)

    if (!merged[key]) {
      merged[key] = {
        ...item, // Sao chép các thuộc tính từ item
        settlementTargetAmount: item.settlementTargetAmount, // Khởi tạo với giá trị ban đầu
        settlementAmount: item.settlementAmount // Khởi tạo với giá trị ban đầu
      };
    } else {
      // Nếu có rồi, gộp settlementTargetAmount và settlementAmount
      merged[key].settlementTargetAmount += item.settlementTargetAmount;
      merged[key].settlementAmount += item.settlementAmount;
    }
  });

  return Object.values(merged);
}

export default function SettlementInforModal() {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [searchType, setSearchType] = useState("pending");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (dialogueData) {
      setData(mergeItems(dialogueData));
    }
  }, [dialogueData]);

  const filterData = useMemo(() => {
    return !searchType
      ? data
      : data.filter((data) => data.status === searchType);
  }, [searchType, data]);

  const mapData = [
    {
      Header: "No",
      Cell: ({ index }) => <span>{parseInt(index) + 1}</span>
    },
    {
      Header: "이름",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          style={{ backgroundColor: "#fff" }}
          // onClick={() => handleInfo(row?._id)}
        >
          {row?.applicant?.nickname}
        </button>
      )
    },
    {
      Header: `전화번호`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.applicant?.phoneNumber}</span>
      )
    },
    {
      Header: `입금 계좌`,
      Cell: ({ row }) => (
        <div className="text-center d-flex flex-column">
          <span className="text-capitalize">{row?.depositAccountName}</span>
          <span className="text-capitalize">{row?.depositAccountNumber}</span>
        </div>
      )
    },

    {
      Header: "총 정산 대상 금액",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.settlementTargetAmount}</span>
      )
    },
    {
      Header: `총 정산금액`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.settlementAmount}</span>
      )
    },
    {
      Header: `정산 여부`,
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.status ? mapStatusToKo[row?.status] : ""}
        </span>
      )
    }
  ];

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="mainDiaogBox position-relative">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">정산 정보</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <p className="mb-4">계좌별 정산 정보</p>
                <div className="d-flex flex-column inputData">
                  <label>정산 상태</label>
                  <select
                    value={searchType}
                    className="inputTypeSearch"
                    style={{
                      height: "40px",
                      marginTop: "0",
                      width: "110px",
                      minWidth: "110px"
                    }}
                    placeholder="선택"
                    onChange={(e) => setSearchType(e.target.value)}
                  >
                    {typeSearch.map((item) => (
                      <option value={item.value} key={item.text}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12">
                  <Table
                    data={filterData}
                    mapData={mapData}
                    classWrapper={"mainTable mainTableModal"}
                    PerPage={-1}
                    Page={page}
                    type={"client"}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center gap-4">
                  <Button
                    type={`submit`}
                    className={` text-white m10-left`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`확인`}
                    onClick={() => dispatch(closeDialog())}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
