import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirm } from "../../../util/Alert";
import Title from "../../extras/Title";
import Pagination from "../../extras/Pagination";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "./../../extras/Table";
import Button from "../../extras/Button";
import AddPermissionModal from "./AddPermissionModal";
import { getAllPermission } from "../../../redux/slice/permissionSlice";

export const PermissionList = () => {
  const [data, setData] = useState([]);

  const { permission } = useSelector((state) => state.permission);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const fetchData = useCallback(() => {
    dispatch(getAllPermission({}));
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setData(permission);
  }, [permission]);

  const noticeTable = [
    {
      Header: "번호",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "분류",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenEdit(row)}
        >
          {row?.category}
        </button>
      )
    },
    {
      Header: "매장 관리",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.storeManagement}
        />
      )
    },
    {
      Header: "예약 리스트",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.reservationManagement}
        />
      )
    },
    {
      Header: "	회원 관리",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.memberManagement}
        />
      )
    },
    {
      Header: "정산 관리",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.settlementManagement}
        />
      )
    },
    {
      Header: "공지사항 설정",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.announcementManagement}
        />
      )
    },
    {
      Header: "기타  앱 수정",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.otherAppManagement}
        />
      )
    },
    {
      Header: "관리자 등록",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.adminManagement}
        />
      )
    }
  ];

  const handleDelete = async (id) => {
    try {
      const data = await confirm({
        title: "FAQ 삭제",
        text: "해당 FAQ를 삭제하시겠습니까?",
        confirm: "확인",
        cancle: "취소"
      });
      const yes = data?.isConfirmed;
      console.log("yes", yes);
      if (yes) {
        // dispatch(salonDelete(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenEdit = (row) => {
    dispatch(openDialog({ type: "addPermission", data: row }));
  };

  const handleOpenAdd = () => {
    dispatch(openDialog({ type: "addPermission", data: null }));
  };

  return (
    <div className="userTable">
      <Title name="권한 관리" />

      <div className="betBox mb-3 justify-content-end">
        <Button
          className={`bg-button-excel p-10 text-black font-bold m10-bottom ms-auto mt-auto`}
          text={`추가`}
          style={{ height: "40px" }}
          // bIcon={`fa-solid fa-user-plus`}
          onClick={() => handleOpenAdd()}
        />
      </div>
      <div>
        <Table
          data={data}
          mapData={noticeTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"client"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={permission.length}
        />
      </div>
      {dialogue && dialogueType === "addPermission" && (
        <AddPermissionModal refetch={fetchData} />
      )}
    </div>
  );
};
