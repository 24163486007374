import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { confirm, warning } from "../../../util/Alert";
import Title from "../../extras/Title";
import Pagination from "../../extras/Pagination";
import { getAllSalons, salonDelete } from "../../../redux/slice/salonSlice";
import moment from "moment";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "./../../extras/Table";
import Button from "../../extras/Button";
import AddNoticeModal from "./AddNoticeModal";
import { deleteNotice, getAllNotice } from "../../../redux/slice/noticeSlice";
import { SearchingWithSelect } from "../../extras/Searching";

const typeSearch = [
  {
    value: "title",
    text: "제목"
  },
  {
    value: "content",
    text: "내용"
  }
];

export const NoticeList = () => {
  const [data, setData] = useState([]);

  const { notice, total } = useSelector((state) => state.notice);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState({
    type: "",
    text: ""
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const fetchData = useCallback(() => {
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
      searchText: search.text,
      searchType: search.type,
      type: "user"
    };
    dispatch(getAllNotice(payload));
  }, [dispatch, page, rowsPerPage, search.text, search.type]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setData(notice);
  }, [notice]);

  const noticeTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{total - ((page + 1 - 1) * rowsPerPage + index)}</span>
      )
    },
    {
      Header: "공지 제목",
      Cell: ({ row }) => <span>{row?.title}</span>
    },
    {
      Header: "내용",
      tdClass: "colContentCoupon",
      Cell: ({ row }) => <span className="truncate-line-2">{row?.content}</span>
    },
    {
      Header: "최종 수정일",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.updatedAt && moment(row.updatedAt).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: "수정",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenEdit(row)}
        >
          수정
        </button>
      )
    },
    {
      Header: "삭제",
      Cell: ({ row }) => (
        <span className="">
          <button
            className="py-1 text-decoration-underline"
            // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => handleDelete(row?.noticeId)}
          >
            삭제
          </button>
        </span>
      )
    }
  ];

  const handleSearch = (stringSearch, type) => {
    if (type !== search.type || stringSearch !== search.text) {
      setPage(0);
    }
    setSearch({
      text: stringSearch,
      type: type
    });
  };
  const handleDelete = async (id) => {
    try {
      const data = await confirm({
        title: "공지사항 삭제",
        text: "해당 공지사항을 삭제하시겠습니까?",
        confirm: "확인",
        cancle: "취소"
      });
      const yes = data?.isConfirmed;
      if (yes) {
        dispatch(deleteNotice(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenAdd = () => {
    dispatch(openDialog({ type: "addNotice", data: null }));
  };

  const handleOpenEdit = (row) => {
    dispatch(openDialog({ type: "addNotice", data: row }));
  };

  return (
    <div className="userTable">
      <Title name="공지사항 등록" />

      <div className="betBox mb-3">
        <div className="col-md-8 col-lg-7 inputData">
          <label>검색</label>
          <SearchingWithSelect
            type={`server`}
            typeSearch={typeSearch}
            handleSearch={handleSearch}
            selectPlaceholder={"선택"}
            button
          />
        </div>
        <Button
          className={`d-flex align-items-center bg-button p-10 text-white m10-bottom ms-auto mt-auto`}
          style={{ marginLeft: "20px", height: "40px" }}
          text={`등록`}
          // bIcon={`fa-solid fa-user-plus`}
          onClick={() => handleOpenAdd()}
        />
      </div>
      <div>
        <Table
          data={data}
          mapData={noticeTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"server"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
      {dialogue && dialogueType === "addNotice" && (
        <AddNoticeModal refetch={fetchData} />
      )}
    </div>
  );
};
