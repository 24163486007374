/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import Table from "../../extras/Table";
import Pagination from "../../extras/Pagination";
import Title from "../../extras/Title";

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllReview, deleteReview } from "../../../redux/slice/reviewSlice";
import { warning } from "../../../util/Alert";
import Rating from "@mui/material/Rating";
import male from "../../../assets/images/male.png";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const SearchingWithSelect = (props) => {
  const [search, setSearch] = useState("");
  const { handleSearch } = props;

  const handleSearchButton = () => {
    handleSearch(search);
  };

  return (
    <>
      <div className="">
        <div className="inputData inputDataSearch d-flex gap-4">
          <input
            type="search"
            id="search"
            // placeholder="Searching for..."
            className="bg-none m0-top w-100"
            style={{
              fontWeight: "500",
              height: "40px",
              width: "110px",
              maxWidth: "110px"
            }}
            value="이름"
            disabled
          />
          <input
            type="search"
            id="search"
            // placeholder="Searching for..."
            className="bg-none m0-top w-100"
            style={{ fontWeight: "500", height: "40px" }}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button
            className="py-1 bg-button-search"
            style={{
              borderRadius: "8px",
              height: "40px",
              width: "100px",
              minWidth: "100px"
            }}
            onClick={() => handleSearchButton()}
          >
            검색
          </button>
        </div>
      </div>
    </>
  );
};
export const Review = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const { review, total } = useSelector((state) => state.review);
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
      nickname: search
    };
    dispatch(getAllReview({ ...payload }));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    setData(review);
  }, [review]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const handleInfo = (row) => {
    navigate("/admin/user/userProfile", {
      state: row
    });
  };

  const handleExpertInfo = (id) => {
    navigate("/admin/expert/getExpertProfile", {
      state: {
        id
      }
    });
  };

  const reviewTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "작성일시",
      Cell: ({ row }) => (
        <span>
          {row?.createdAt && moment(row.createdAt).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: "예약 매장",
      Cell: ({ row }) => <span>{row?.reservation?.store?.name}</span>
    },
    {
      Header: "예약 바텐더",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleInfo(row?.reservation?.bartender)}
        >
          {row?.reservation?.bartender?.nickname}
        </button>
      )
    },
    {
      Header: "작성자",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleInfo(row?.reservation?.user)}
        >
          {row?.reservation?.user?.nickname}
        </button>
      )
    },
    {
      Header: "별점",
      Cell: ({ row }) => <span>{row?.star}</span>
    },
    {
      Header: "내용",
      Cell: ({ row }) => <span className="truncate-line-2">{row?.comment}</span>
    }
  ];

  const handleDelete = (id) => {
    const data = warning("Delete");
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(deleteReview(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSearch = (value) => {
    if (value !== search) {
      setPage(0);
    }
    setSearch(value);
  };

  return (
    <div className="mainExpert">
      <Title name="리뷰 관리" />
      <div className="col-md-8 col-lg-7 inputData">
        <label>검색</label>
        <SearchingWithSelect handleSearch={handleSearch} />
      </div>
      <div>
        <Table
          data={data}
          mapData={reviewTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"server"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
    </div>
  );
};
