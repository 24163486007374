import React, { useEffect, useRef, useState } from "react";
import { ExInput, SelectV2, Textarea } from "../../extras/Input";
import { getSetting, updateSetting } from "../../../redux/slice/settingSlice";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import moment from "moment";

const EditModal = () => {
  const loadingRef = useRef(false);
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [text, setText] = useState("");
  const [error, setError] = useState({
    text: ""
  });

  useEffect(() => {
    if (dialogueData) {
      setText(dialogueData);
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    loadingRef.current = true;
    try {
      // if (!text) {
      //   let error = {};
      //   if (!text) error.text = "Fixed message content is required";

      //   return setError({ ...error });
      // } else {
      const body = {
        fixedMessage: text
      };
      const res = await dispatch(updateSetting(body));
      console.log("res", res);
      if (res?.payload?.data?.data) {
        loadingRef.current = false;
        dispatch(closeDialog());
        dispatch(getSetting());
      }
      // }
    } catch (err) {
      console.log("error", err);
      loadingRef.current = false;
    }
  };

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-md-7 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">고정 메시지 수정</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="col-12"></div>
                <div className="col-12">
                  <Textarea
                    value={text}
                    row={5}
                    onChange={(e) => setText(e.target.value)}
                    label={`고정 메시지 내용`}
                  />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-center align-items-center gap-2">
                <Button
                  className={`bg-gray text-light`}
                  text={`취소`}
                  type={`button`}
                  onClick={() => dispatch(closeDialog())}
                />
                <Button
                  type={`submit`}
                  className={`text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`등록`}
                  disabled={loadingRef.current}
                  onClick={(e) => handleSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
