import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToast from "../component/extras/CustomToast";

// Custom hook to show toast with specific data
const useCustomToast = () => {
  const showCustomToast = ({ title, storeName, menu, stockRemaining }) => {
    toast(
      <CustomToast
        title={title}
        storeName={storeName}
        menu={menu}
        stockRemaining={stockRemaining}
      />
    );
  };

  return showCustomToast;
};

export default useCustomToast;
