import React, { useEffect, useMemo, useRef, useState } from "react";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Table from "../../extras/Table";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extras/Button";
import { ExInput } from "../../extras/Input";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { getAllUsersByPhone } from "../../../redux/slice/userSlice";
import { toast } from "react-toastify";
import { issueCoupon } from "../../../redux/slice/couponSlice";
import Pagination from "../../extras/Pagination";

export default function IssueCouponModal() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const fileInputRef = useRef(null);
  const loadingRef = useRef(false);

  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleOpenProfile = (row) => {
    navigate("/admin/user/userProfile", {
      state: {
        row
      }
    });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const abuf = e.target.result;
          const wb = XLSX.read(abuf, { type: "array" });

          const ws = wb.Sheets[wb.SheetNames[0]];

          const jsonData = XLSX.utils.sheet_to_json(ws, { header: 1 });

          const newData = [];
          jsonData.forEach((row) => {
            if (row.length > 0) {
              newData.push(row[0] + "");
            }
          });
          const res = await dispatch(
            getAllUsersByPhone({ phoneNumbers: newData })
          );
          if (res?.payload?.data) {
            setData(res.payload.data);
          }
        } catch (error) {
          console.log(error);
        }
      };

      reader.readAsArrayBuffer(file);
    }
    event.target.value = "";
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const mapData = [
    {
      tdClass: "colOpitonName",
      Header: "이름",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.fullname}</span>
      )
    },
    {
      Header: `닉네임`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.nickname}</span>
      )
    },
    {
      Header: `휴대폰 번호`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.phoneNumber}</span>
      )
    },

    {
      Header: "보유 포인트",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.pointBalance || 0}p</span>
      )
    },
    {
      Header: `바텐더 등록 여부`,
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.isBartender ? "등록" : "미등록"}
        </span>
      )
    },
    {
      Header: `프로필 상세`,
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenProfile(row)}
        >
          프로필 보기
        </button>
      )
    }
  ];

  const handleSubmit = async () => {
    loadingRef.current = true;
    try {
      if (!data.length) {
        toast.error("Please upload members");
      } else {
        const body = { userIds: data.map((item) => item.id) };

        let payload = {
          body,
          id: dialogueData.couponId
        };
        const res = await dispatch(issueCoupon(payload));
        console.log("res.payload", res.payload);
        if (res.payload?.data?.data) {
          dispatch(closeDialog());
        }
      }
    } catch (err) {
      loadingRef.current = false;
      console.log("err.message", err);
    }
    loadingRef.current = false;
  };

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="mainDiaogBox position-relative">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">쿠폰 발급</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <p className="mb-1 titleTable">지급 유저 목록</p>
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"쿠폰 명"}
                    disabled
                    value={dialogueData?.couponTitle}
                  />
                </div>
                <div className="col-12 d-flex align-items-center">
                  <div className="inputData">
                    <label htmlFor="">발급 유저 등록</label>
                  </div>
                  <Button
                    className={`bg-gray text-light ms-2`}
                    text={`Excel 업로드`}
                    type={`button`}
                    onClick={() => triggerFileInput()}
                  />
                </div>
                <div className="col-12">
                  <Table
                    data={data}
                    mapData={mapData}
                    classWrapper={"mainTable mainTableModal"}
                    PerPage={rowsPerPage}
                    Page={page}
                    type={"client"}
                  />
                  <Pagination
                    type={"client"}
                    serverPage={page}
                    setServerPage={setPage}
                    serverPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    totalData={data?.length}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center gap-4">
                  <Button
                    className={`bg-gray text-light`}
                    text={`취소`}
                    type={`button`}
                    onClick={() => dispatch(closeDialog())}
                  />
                  <Button
                    type={`submit`}
                    className={` text-white m10-left`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`발급`}
                    disabled={loadingRef.current}
                    onClick={(e) => handleSubmit(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        accept=".xlsx,.xls"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
    </div>
  );
}
