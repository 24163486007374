import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confirm } from "../../../util/Alert";
import Title from "../../extras/Title";
import Pagination from "../../extras/Pagination";
import { getAllSalons, salonDelete } from "../../../redux/slice/salonSlice";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "./../../extras/Table";
import Button from "../../extras/Button";
import AddFAQModal from "./AddFAQModal";
import { deleteFaq, getAllFaq } from "../../../redux/slice/faqSlice";
import { SearchingWithSelect } from "./../../extras/Searching";
import { useCallback } from "react";

const typeSearch = [
  {
    value: "title",
    text: "제목"
  },
  {
    value: "content",
    text: "내용"
  },
  {
    value: "category",
    text: "카테고리"
  }
];

const mapTypeKoToEn = (type) => {
  if (["예약", "예", "약"].includes(type)) {
    return "RESERVATION";
  } else if (["결제", "결", "제"].includes(type)) {
    return "PAYMENT";
  } else if (["바텐더", "바텐", "텐더", "바", "텐", "더"].includes(type)) {
    return "BARTENDER";
  } else {
    return type;
  }
};

const mapTypeToKo = {
  RESERVATION: "예약",
  PAYMENT: "결제",
  BARTENDER: "바텐더"
};

export const FAQList = () => {
  const [data, setData] = useState([]);

  const { faq, total } = useSelector((state) => state.faq);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState({
    text: "",
    type: ""
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const fetchData = useCallback(() => {
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
      searchText:
        search.type === "category"
          ? mapTypeKoToEn(search.text?.trim())
          : search.text,
      searchType: search.type
    };
    console.log("payload", payload.searchText);
    dispatch(getAllFaq(payload));
  }, [dispatch, page, rowsPerPage, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setData(faq);
  }, [faq]);

  const noticeTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "카테고리",
      Cell: ({ row }) => (
        <span>{row?.category ? mapTypeToKo[row?.category] : ""}</span>
      )
    },
    {
      Header: "질문 제목",
      Cell: ({ row }) => <span>{row?.title}</span>
    },
    {
      Header: "내용",
      tdClass: "colContentCoupon",
      Cell: ({ row }) => <span className="truncate-line-2">{row?.content}</span>
    },

    {
      Header: "수정",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenEdit(row)}
        >
          수정
        </button>
      )
    },
    {
      Header: "삭제",
      Cell: ({ row }) => (
        <span className="">
          <button
            className="py-1 text-decoration-underline"
            // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => handleDelete(row?.id)}
          >
            삭제
          </button>
        </span>
      )
    }
  ];

  const handleDelete = async (id) => {
    try {
      const data = await confirm({
        title: "FAQ 삭제",
        text: "해당 FAQ를 삭제하시겠습니까?",
        confirm: "확인",
        cancle: "취소"
      });
      const yes = data?.isConfirmed;
      if (yes) {
        dispatch(deleteFaq(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenAdd = () => {
    dispatch(openDialog({ type: "addFAQ", data: null }));
  };

  const handleOpenEdit = (row) => {
    dispatch(openDialog({ type: "addFAQ", data: row }));
  };

  const handleSearch = (stringSearch, type) => {
    if (stringSearch !== search.text || type !== search.type) {
      setPage(0);
    }
    setSearch({
      text: stringSearch,
      type: type
    });
  };

  return (
    <div className="userTable">
      <Title name="FAQ 등록" />

      <div className="betBox mb-3">
        <div className="col-md-8 col-lg-7 inputData">
          <label>검색</label>
          <SearchingWithSelect
            type={`server`}
            data={data}
            typeSearch={typeSearch}
            setData={setData}
            column={noticeTable}
            handleSearch={handleSearch}
            selectPlaceholder={"선택"}
            button
          />
        </div>
        <Button
          className={`d-flex align-items-center bg-button p-10 text-white m10-bottom ms-auto mt-auto`}
          style={{ marginLeft: "20px", height: "40px" }}
          text={`등록`}
          // bIcon={`fa-solid fa-user-plus`}
          onClick={() => handleOpenAdd()}
        />
      </div>
      <div>
        <Table
          data={data}
          mapData={noticeTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"server"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
      {dialogue && dialogueType === "addFAQ" && (
        <AddFAQModal refetch={fetchData} />
      )}
    </div>
  );
};
