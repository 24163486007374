/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../component/api/axiosApi";
import { Success } from "../../component/api/toastServices";
import { genQueryString } from "./../../util/fuction";

const initialState = {
  isLoading: false,
  isSkeleton: false,
  csList: [],
  total: null
};

export const getAllCs = createAsyncThunk("admin/cs/getAll", async (payload) => {
  return apiInstance.get(genQueryString(`admin/cs`, payload));
});

export const updateCs = createAsyncThunk("admin/cs/update", async (payload) => {
  return apiInstance.put(`admin/cs/${payload.id}`, payload.body);
});

const csSlice = createSlice({
  name: "sSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCs.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllCs.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.csList = action.payload.data;
      state.total = action.payload.count;
    });

    builder.addCase(getAllCs.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(updateCs.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateCs.fulfilled, (state, action) => {
      if (action?.payload?.data?.data) {
        Success("CS Updated Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(updateCs.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});
export default csSlice.reducer;
