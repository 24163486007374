import React from "react";

const ToggleSwitch = (props) => {
  return (
    <>
      <label className="switch">
        <input
          type="checkbox"
          disabled={props.disabled}
          checked={props.value}
          onClick={props.onClick}
        />
        <div className="slider"></div>
        <div className="slider-card">
          <div className="slider-card-face slider-card-front"></div>
          <div className="slider-card-face slider-card-back"></div>
        </div>
      </label>
    </>
  );
};

export default ToggleSwitch;
