// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Button from "../../extras/Button";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../extras/Title";
import {
  getSetting,
  handleSetting,
  maintenanceMode,
  updateSetting
} from "../../../redux/slice/settingSlice";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { ExInput, Textarea } from "../../extras/Input";
import { isNumber } from "../../../util/fuction";

const StockAlarmSetting = () => {
  const loadingRef = useRef(false);
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state.setting);

  const [stock, setStock] = useState();

  const [error, setError] = useState({
    stock: ""
  });

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  useEffect(() => {
    if (setting) {
      setStock(setting.minimumInventoryAlarm);
    }
  }, [setting]);

  const onsubmit = async (e) => {
    e.preventDefault();
    try {
      if (!stock) {
        let error = {};
        if (!stock)
          error.stock = "Stock quantity criteria to send alarm is required";

        return setError({ ...error });
      } else {
        loadingRef.current = true;
        const body = {
          minimumInventoryAlarm: Number(stock)
        };
        const res = await dispatch(updateSetting(body));
        console.log("res", res);
        if (res?.payload?.data?.data) {
          loadingRef.current = false;
          dispatch(getSetting());
        }
      }
    } catch (err) {
      loadingRef.current = false;
      console.log(err);
    }
    loadingRef.current = false;
  };

  return (
    <div className="mainSetting">
      <Title name="재고 알람 설정" />
      <div className="settingBox">
        <div className="row">
          <div className="col-12 d-flex gap-2 align-items-end inputData">
            <ExInput
              type={`text`}
              value={stock}
              label={`재고 알람 기준 설정`}
              onChange={(e) => {
                if (isNumber(e.target.value) || e.target.value === "") {
                  setStock(e.target.value);
                  setError({
                    stock: e.target.value
                      ? ""
                      : "Stock quantity criteria to send alarm is required"
                  });
                }
              }}
              errorMessage={error.stock}
            />
            <span
              style={{
                marginBottom: error.stock ? "35px" : "10px"
              }}
            >
              개 이하일 경우 알람 발송
            </span>
          </div>
        </div>
        <div className=" d-flex justify-content-end">
          <div className="  formFooter">
            <Button
              type={`submit`}
              className={`text-light m10-left fw-bold`}
              text={`저장`}
              style={{ backgroundColor: "#1ebc1e" }}
              onClick={onsubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default StockAlarmSetting;
