import React, { useEffect, useMemo, useRef, useState } from "react";
import { ExInput, SelectV2, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import { bulkUpdateHashTag } from "../../../redux/slice/hashTagSlice";
import { SuccessStatusCode } from "../../../util/constants";

const getAllHashTags = (hashTags) => {
  let hashTagsList = [];
  hashTags.forEach((category) => {
    const hashTags = (category?.hashTags || []).map((hashTag) => ({
      ...hashTag,
      hashTagCategoryId: category.id
    }));
    hashTagsList = [...hashTagsList, ...hashTags];
  });
  return hashTagsList;
};

const RecommendTag = (props) => {
  const dispatch = useDispatch();
  const loadingRef = useRef(false);
  const preData = useRef([]);
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [hashTagList, setHashTagList] = useState([]);

  useEffect(() => {
    if (dialogueData) {
      const data = getAllHashTags(dialogueData || []);
      preData.current = data;
      setHashTagList(data);
    }
  }, [dialogueData]);

  const selected = useMemo(() => {
    return hashTagList.filter((tag) => tag.isRecommended).length;
  }, [hashTagList]);

  const handleSelect = (id) => {
    let newHashTagList = [...hashTagList];
    newHashTagList = newHashTagList.map((tag) => {
      if (tag.id === id) {
        return {
          ...tag,
          isRecommended:
            selected === 5 && !tag.isRecommended === true
              ? tag.isRecommended
              : !tag.isRecommended
        };
      }
      return tag;
    });
    setHashTagList([...newHashTagList]);
  };

  const handleDataUpdate = () => {
    const prevData = preData.current;
    const updateData = hashTagList
      .filter(
        (item, index) => item.id && item.isRecommended !== prevData[index]
      )
      .map((item, index) => ({
        ...item,
        order: index + 1
      }));

    return {
      hashtagsUpdate: updateData
    };
  };

  const handleSubmit = async () => {
    try {
      const body = {
        ...handleDataUpdate()
      };

      const res = await dispatch(bulkUpdateHashTag(body));

      if (res.payload?.statusCode === SuccessStatusCode) {
        setHashTagList([]);
        dispatch(closeDialog());
        props.refetch && props.refetch();
      }
    } catch (err) {
      loadingRef.current = false;
      console.log("err.message", err);
    }
    loadingRef.current = false;
  };

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-md-8 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">
                    추천 해시태그 설정 ({selected}/5개)
                  </h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div
                className="row align-items-start formBody px-2"
                style={{
                  columnGap: "20px"
                }}
              >
                {hashTagList.map((tag) => (
                  <div
                    className={`border rounded col-4 d-flex align-items-center cursor-pointer px-20 py-0 ${
                      tag.isRecommended ? "bg-gray" : "bg-white"
                    }`}
                    style={{
                      height: "40px",
                      flexBasis: "calc(100%/3 - 20px)"
                    }}
                    key={tag.id}
                    onClick={() => handleSelect(tag.id)}
                  >
                    {tag.hashTag}
                  </div>
                ))}
              </div>

              <div className="col-12">
                <div className="d-flex justify-content-end align-items-center gap-2">
                  <Button
                    className={`bg-gray text-light`}
                    text={`취소`}
                    type={`button`}
                    onClick={() => dispatch(closeDialog())}
                  />
                  <Button
                    type={`submit`}
                    className={`text-white m10-left`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`저장`}
                    disabled={loadingRef.current}
                    onClick={() => handleSubmit()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendTag;
