/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import Button from "../../extras/Button";
import Table from "./Table";
import Pagination from "../../extras/Pagination";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Title from "../../extras/Title";
import { confirm, warning } from "../../../util/Alert";
import {
  getAllServices,
  deleteService,
  serviceStatus
} from "../../../redux/slice/serviceSlice";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServiceDialogue from "./ServiceDialogue";
import Searching from "../../extras/Searching";
import { useLocation } from "react-router-dom";
import MenuList from "./MenuList";
import StockList from "./StockList";

const handleDataTable = (data = []) => {
  const rows = [];
  data.forEach((element, idx) => {
    const options = element.options || [];
    options.forEach((option, index) => {
      if (index === 0) {
        rows.push({
          type: element.type,
          menuName: element.menuName,
          optionsName: option.name,
          optionsPrice: option.price,
          optionsPaymentAmount: option.paymentAmount,
          optionsReserverTime: option.reserverTime,
          optionsStock: option.stock,
          id: element.id,
          rowSpan: options.length,
          isRender: true,
          index: idx + 1
        });
      } else {
        rows.push({
          type: "",
          menuName: "",
          optionsName: option.name,
          optionsPrice: option.price,
          optionsPaymentAmount: option.paymentAmount,
          optionsReserverTime: option.reserverTime,
          optionsStock: option.stock,
          isRender: false
        });
      }
    });
  });
  return rows;
};

const Service = () => {
  const dummy = useMemo(() => {
    return [
      {
        id: 1,
        type: "세트",
        menuName: "A Set",
        order: 1,
        description: "description1",
        options: [
          {
            name: "조니워커 블랙 1000mL",
            price: "50000원",
            paymentAmount: "40000원",
            reserverTime: "2시간",
            stock: "10 개"
          },
          {
            name: "조니워커 블랙 1000mL",
            price: "50000원",
            paymentAmount: "40000원",
            reserverTime: "2시간",
            stock: "10 개"
          },
          {
            name: "조니워커 블랙 1000mL",
            price: "50000원",
            paymentAmount: "40000원",
            reserverTime: "2시간",
            stock: "10 개"
          }
        ]
      },
      {
        id: 2,
        type: "세트",
        menuName: "B Set",
        order: 2,
        description: "description2",
        options: [
          {
            name: "잭다니엘 750mL",
            price: "50000원",
            paymentAmount: "40000원",
            reserverTime: "2시간",
            stock: "10 개"
          },
          {
            name: "잭다니엘 500mL",
            price: "50000원",
            paymentAmount: "40000원",
            reserverTime: "2시간",
            stock: "10 개"
          }
        ]
      },
      {
        id: 3,
        type: "단품",
        menuName: "프레첼",
        order: 3,
        description: "description3",
        options: [
          {
            name: "",
            price: "10000원",
            paymentAmount: "40000원",
            reserverTime: "",
            stock: "10 개"
          }
        ]
      },
      {
        id: 4,
        type: "단품",
        menuName: "프레첼",
        order: 4,
        description: "description4",
        options: [
          {
            name: "",
            price: "10000원",
            paymentAmount: "40000원",
            reserverTime: "",
            stock: "10 개"
          }
        ]
      }
    ];
  }, []);

  const dispatch = useDispatch();

  const { setting } = useSelector((state) => state.setting);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const { service, total } = useSelector((state) => state.service);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("ALL");

  const state = useLocation();

  const payload = {
    start: page,
    limit: rowsPerPage,
    search
  };

  // useEffect(() => {
  //   dispatch(getAllServices(payload));
  // }, [page, rowsPerPage, search]);

  // useEffect(() => {
  //   setData(service);
  // }, [service]);

  useEffect(() => {
    setData(handleDataTable(service));
  }, [service]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  function handleOpenEdit(id) {
    const data = dummy.find((item) => item.id === id);
    dispatch(openDialog({ type: "service", data }));
  }

  const handleStatus = (id) => {
    dispatch(serviceStatus(id));
  };

  const handleDelete = (id) => {
    // const data = warning("Delete");
    const data = confirm({
      title: "메뉴 삭제",
      text: "해당 메뉴를 삭제하시겠습니까?",
      confirm: "확인",
      cancle: "취소"
    });
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          // dispatch(deleteService(id));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="mainCategory">
      <Title
        name={
          state?.state?.row?.storeName
            ? `스토어바 ${state ? state?.state?.row?.storeName : ""}역점 메뉴`
            : ""
        }
      />
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link servicesTab active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            메뉴 관리
          </button>
        </li>
        <li className="nav-item ms-2" role="presentation">
          <button
            className="nav-link servicesTab"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            재고 관리
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <MenuList storeId={state?.state?.row?.storeId} />
        </div>
        <div
          className="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <StockList storeId={state?.state?.row?.storeId} />
        </div>
      </div>
    </div>
  );
};

export default Service;
