import React, { useEffect, useMemo, useState } from "react";
import { ExInput, SelectV2, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import moment from "moment";
import {
  addAdmin,
  checkEmailAdmin,
  updateAdmin
} from "../../../redux/slice/adminSlice";
import { toast } from "react-toastify";
import { getAllPermission } from "../../../redux/slice/permissionSlice";
import { useRef } from "react";

const optionType = [
  {
    value: 1,
    name: "root"
  }
];

const AddAdminModal = (props) => {
  const dispatch = useDispatch();
  const loadingRef = useRef(false);
  const { dialogueData, dialogueType } = useSelector((state) => state.dialogue);
  const { permission } = useSelector((state) => state.permission);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");

  const [error, setError] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    type: ""
  });

  useEffect(() => {
    if (dialogueData) {
      setName(dialogueData?.nickname);
      setEmail(dialogueData?.email);
      setPhone(dialogueData?.phoneNumber);
    }
  }, [dialogueData]);

  useEffect(() => {
    if (dialogueType) {
      dispatch(getAllPermission({}));
    }
  }, [dispatch, dialogueType]);

  const optionCatgory = useMemo(() => {
    return permission.map((item) => ({
      value: item.id,
      name: item.category
    }));
  }, [permission]);

  useEffect(() => {
    if (dialogueData) {
      const selectedType = optionCatgory.find(
        (item) => item.name === dialogueData?.permissionCategory?.category
      );

      setType(selectedType?.value || optionCatgory?.[0]?.value);
    }
  }, [optionCatgory, dialogueData]);

  const handleSetError = (field, value) => {
    let newError = {};
    if (field === "name") {
      newError.name = !value ? "Name is required" : "";
    }
    if (field === "email") {
      newError.email = !value ? "Email Link is required" : "";
    }
    if (field === "password") {
      newError.password = !value ? "Password is required" : "";
      // !value && !dialogueData?.id ? "Password is required" : "";
    }
    if (field === "confirmPassword") {
      newError.confirmPassword = !value ? "Confirm Password is required" : "";
      // !value && !dialogueData?.id ? "Confirm Password is required" : "";
    }
    if (field === "phone") {
      newError.phone = !value ? "Phone is required" : "";
    }
    if (field === "type") {
      newError.type = !value ? "Type is required" : "";
    }
    setError((preState) => ({ ...error, ...newError }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    loadingRef.current = true;
    if (
      !name ||
      !email ||
      // (!password && !dialogueData?.id) ||
      // (!confirmPassword && !dialogueData?.id) ||
      !password ||
      !confirmPassword ||
      !phone ||
      !type ||
      password !== confirmPassword
    ) {
      let error = {};
      if (!name) error.name = "Name is required";
      if (!email) error.email = "Email is required";
      // if (!password && !dialogueData?.id)
      //   error.password = "Password is required";
      // if (!confirmPassword && !dialogueData?.id)
      //   error.confirmPassword = "Confirm Password is required";
      if (!password) error.password = "Password is required";
      if (!confirmPassword)
        error.confirmPassword = "Confirm Password is required";
      if (!phone) error.phone = "Phone is Required";
      if (!type) error.type = "Type is Required";
      if (password && confirmPassword && password !== confirmPassword)
        error.confirmPassword = "Confirm Passwords do not match";
      loadingRef.current = false;
      return setError({ ...error });
    } else {
      try {
        let body = {
          nickname: name,
          email: email,
          phoneNumber: phone,
          permissionCategoryId: Number(type)
        };
        let res = null;
        if (dialogueData?.id) {
          if (password) {
            body = { ...body, password: password };
          }
          res = await dispatch(updateAdmin({ id: dialogueData.id, body }));
        } else {
          body = { ...body, password: password };
          res = await dispatch(addAdmin(body));
        }
        console.log("res", res);
        if (res?.payload?.data?.data) {
          loadingRef.current = false;
          dispatch(closeDialog());
          props.refetch && props.refetch();
        }
      } catch (error) {
        console.log(error);
        loadingRef.current = false;
      }
    }
  };

  const handleCheckEmail = async () => {
    const res = await dispatch(
      checkEmailAdmin({
        email
      })
    );
    if (res?.payload?.data?.isExist) {
      setError({ ...error, email: "Email already exists" });
    } else {
      toast.success("You can use this email.");
    }
  };

  return (
    <div className="dialog focusNone">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-md-5 col-8">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">관리자 추가</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div
                className="row align-items-start formBody"
                style={{ height: "calc(100svh - 200px)" }}
              >
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"이름"}
                    value={name}
                    errorMessage={error.name}
                    placeholder="이름을 입력하세요."
                    onChange={(e) => {
                      setName(e.target.value);
                      handleSetError("name", e.target.value);
                    }}
                  />
                </div>
                <div className="col-12 d-flex">
                  <ExInput
                    type={`text`}
                    label={"이메일(ID)"}
                    newClass="w-100"
                    className="w-100"
                    value={email}
                    errorMessage={error.email}
                    placeholder="이메일을 입력하세요. "
                    onChange={(e) => {
                      setEmail(e.target.value);
                      handleSetError("email", e.target.value);
                    }}
                  />
                  <Button
                    type={`submit`}
                    className={`text-white m10-left d-flex align-items-center justify-content-center m35-top`}
                    style={{
                      backgroundColor: "#E7E6E6",
                      height: "40px",
                      width: "120px",
                      minWidth: "120px",
                      marginBottom: "5px"
                    }}
                    text={`중복 확인`}
                    onClick={(e) => handleCheckEmail()}
                  />
                </div>
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"비밀번호"}
                    value={password}
                    errorMessage={error.password}
                    placeholder="비밀번호를 입력하세요."
                    onChange={(e) => {
                      setPassword(e.target.value);
                      handleSetError("password", e.target.value);
                    }}
                  />
                </div>
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"비밀번호 확인"}
                    value={confirmPassword}
                    errorMessage={error.confirmPassword}
                    placeholder="비밀번호를 재입력하세요."
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      handleSetError("confirmPassword", e.target.value);
                    }}
                  />
                </div>
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"휴대폰번호"}
                    value={phone}
                    errorMessage={error.phone}
                    placeholder="핸드폰 번호를 입력하세요."
                    onChange={(e) => {
                      setPhone(e.target.value);
                      handleSetError("phone", e.target.value);
                    }}
                  />
                </div>
                <div className="col-12">
                  <SelectV2
                    type={`text`}
                    label={"관리자 구분"}
                    value={type}
                    option={optionCatgory}
                    errorMessage={error.type}
                    className={`inputSelectTime`}
                    placeholder="관리자 구분을 선택하세요."
                    onChange={(value) => {
                      setType(value);
                      handleSetError("type", value);
                    }}
                  />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-end align-items-center gap-2">
                <Button
                  type={`submit`}
                  className={`text-white m10-left`}
                  style={{
                    backgroundColor: "#E7E6E6"
                  }}
                  text={`취소`}
                  onClick={() => {
                    dispatch(closeDialog());
                  }}
                />
                <Button
                  type={`submit`}
                  className={`text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`저장`}
                  disabled={loadingRef.current}
                  onClick={(e) => handleSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdminModal;
