import React, { useCallback, useEffect, useMemo, useState } from "react";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Table from "../../extras/Table";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extras/Button";
import { SearchingWithSelect } from "../../extras/Searching";
import Pagination from "../../extras/Pagination";
import { getAllUsers } from "../../../redux/slice/userSlice";

const typeSearch = [
  {
    value: "fullName",
    text: "이름"
  },
  {
    value: "nickName",
    text: "닉네임"
  }
];

export default function UserSearchModal(props) {
  const { onClose, onSelect } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState({
    text: "",
    type: ""
  });
  const [total, setTotal] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const fetchData = useCallback(async () => {
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
      searchText: search.text,
      searchType: search.type,
      role: "USER,BARTENDER"
    };
    const res = await dispatch(getAllUsers(payload));
    console.log("res.payload", res.payload);
    setData(res.payload?.data);
    setTotal(res.payload?.count);
  }, [dispatch, page, rowsPerPage, search.text, search.type]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleSearch = (text, type) => {
    setSearch({ text: text, type: type });
  };

  const mapData = [
    {
      Header: "이름",
      Cell: ({ row }) => <span>{row?.fullname}</span>
    },
    {
      Header: "닉네임",
      Cell: ({ row }) => <span>{row?.nickname}</span>
    },
    {
      Header: `휴대폰 번호`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.phoneNumber}</span>
      )
    },
    {
      Header: `바텐더 등록 여부`,
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.isBartender ? "등록" : "미등록"}
        </span>
      )
    },
    {
      Header: "선택",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          style={{ backgroundColor: "#fff" }}
          onClick={() => {
            onSelect(row);
            onClose();
          }}
        >
          선택
        </button>
      )
    }
  ];

  return (
    <div
      className="dialog"
      style={{
        zIndex: "1000 !important"
      }}
    >
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="mainDiaogBox position-relative">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">사용자 검색</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody hideScrollDialog">
                <div className="col-md-8 col-lg-7 inputData">
                  <SearchingWithSelect
                    type={`server`}
                    data={data}
                    typeSearch={typeSearch}
                    selectPlaceholder="선택"
                    setData={setData}
                    column={mapData}
                    handleSearch={handleSearch}
                    button
                  />
                </div>
                <div className="col-12">
                  <Table
                    data={data}
                    mapData={mapData}
                    classWrapper={"mainTable mainTableModal tableSchedule"}
                    PerPage={rowsPerPage}
                    Page={page}
                    type={"server"}
                  />
                  <div className="d-flex justify-content-end m10-top">
                    <Pagination
                      serverPage={page}
                      setServerPage={setPage}
                      serverPerPage={rowsPerPage}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      totalData={total}
                      hideSelect
                    />
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center gap-4">
                  <Button
                    className={`text-white`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`취소`}
                    onClick={() => onClose()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
