import React from "react";

// Custom Toast component that displays multiple lines of information
const CustomToast = ({ title, storeName, menu, stockRemaining }) => {
  return (
    <div
      style={{
        padding: "10px",
        color: "#333"
      }}
    >
      <div
        className="text-danger"
        style={{ fontWeight: "bold", fontSize: "16px" }}
      >
        {title}
      </div>
      <div
        className="d-flex justify-content-between"
        style={{ marginTop: "5px" }}
      >
        <strong>매장 이름</strong>
        <span>{storeName}</span>
      </div>
      <div
        className="d-flex justify-content-between"
        style={{ marginTop: "5px" }}
      >
        <strong>메뉴</strong> <span>{menu}</span>
      </div>
      <div
        className="d-flex justify-content-between"
        style={{ marginTop: "5px" }}
      >
        <strong>남은 재고</strong>{" "}
        <span className="text-danger">{stockRemaining}</span>
      </div>
    </div>
  );
};

export default CustomToast;
