/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../component/api/axiosApi";
import { DangerRight, Success } from "../../component/api/toastServices";
import { SuccessStatusCode } from "../../util/constants";
import { genQueryString } from "../../util/fuction";

const initialState = {
  isLoading: false,
  isSkeleton: false,
  faq: [],
  total: null
};

export const getAllFaq = createAsyncThunk(
  "admin/faq/getAll",
  async (payload) => {
    return apiInstance.get(genQueryString("admin/faqs", payload));
  }
);

export const addFaq = createAsyncThunk("admin/faq/create", async (payload) => {
  return apiInstance.post(`admin/faqs`, payload);
});

export const updateFaq = createAsyncThunk(
  "admin/faq/update",
  async (payload) => {
    return apiInstance.put(`admin/faqs/${payload.id}`, payload.body);
  }
);

export const deleteFaq = createAsyncThunk("admin/faq/delete", async (id) => {
  const res = await apiInstance.delete(`admin/faqs/${id}`);
  return {
    data: res,
    id
  };
});

const faqSlice = createSlice({
  name: "timeSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllFaq.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllFaq.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.faq = action.payload.data;
      state.total = action.payload.count;
    });

    builder.addCase(getAllFaq.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(addFaq.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addFaq.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        state.total += 1;
        Success("Faq Added Successfully");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(addFaq.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateFaq.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateFaq.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        Success("Faq Updated Successfully");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(updateFaq.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteFaq.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteFaq.fulfilled, (state, action) => {
      if (action?.payload?.data?.statusCode === SuccessStatusCode) {
        state.faq = state.faq.filter(
          (item) => item?.id !== action?.payload?.id
        );

        state.total -= 1;
        Success("Faq Delete Successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(deleteFaq.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});
export default faqSlice.reducer;
