/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import Button from "../../extras/Button";
import Table from "../../extras/Table";
import Pagination from "../../extras/Pagination";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Title from "../../extras/Title";
import {
  getAllCategory,
  categoryDelete,
  categoryStatus
} from "../../../redux/slice/categorySlice";
import { warning } from "../../../util/Alert";
import {
  getComplains,
  solveComplain
} from "../../../redux/slice/complainSlice";
import noImage from "../../../assets/images/noImage.png";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Analytics from "../../extras/Analytics";
import ComplainDialog from "./ComplainDialog";
import { useLocation, useNavigate } from "react-router-dom";
import DateRange from "../../extras/DateRange";
import moment from "moment";

const mapStatusToKo = {
  pending: "미승인",
  rejected: "거절",
  approved: "승인",
  completed: "완료"
};

const Complain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { complain = [], total = 0 } = useSelector((state) => state.complain);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchDate, setSearchDate] = useState({
    startDate: "",
    endDate: ""
  });
  const [page, setPage] = useState(0);
  const [type, setType] = useState(2);
  const [person, setPerson] = useState(1);

  const state = useLocation();

  const fetchData = useCallback(() => {
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
      from: searchDate.startDate
        ? new Date(searchDate.startDate).toISOString()
        : "",
      to: searchDate.endDate ? new Date(searchDate.endDate).toISOString() : "",
      storeId: state?.state?.data?.storeId
        ? state?.state?.data?.storeId?.toString()?.trim()
        : ""
    };
    dispatch(getComplains(payload));
  }, [
    dispatch,
    page,
    rowsPerPage,
    searchDate.endDate,
    searchDate.startDate,
    state?.state?.data?.storeId
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setData(complain);
  }, [complain]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  const handleInfo = (row) => {
    navigate("/admin/user/userProfile", {
      state: {
        row
      }
    });
  };

  const mapTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    ...(!state?.state?.data?.storeId
      ? [
          {
            Header: "매장",
            Cell: ({ row }) => (
              <button
                className="py-1 text-decoration-underline"
                // style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
                style={{ backgroundColor: "#fff" }}
                // onClick={() => handleOpenPaymentModal(row?._id)}
              >
                {row?.store?.name}
              </button>
            )
          }
        ]
      : []),
    {
      Header: "이름",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleInfo(row?.applicant)}
        >
          {row?.applicant?.nickname}
        </button>
      )
    },
    {
      Header: "전화번호",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.applicant?.phoneNumber}</span>
      )
    },
    {
      Header: "이의 신청 일자",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.objection?.createdAt &&
            moment(row.objection.createdAt).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: "정산건 예약 일자",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.reservationDate &&
            moment(row.reservationDate).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: "정산 처리 일자",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.processingDate
            ? moment(row.processingDate).format("YYYY-MM-DD")
            : ""}
        </span>
      )
    },
    {
      Header: "입금 계좌",
      Cell: ({ row }) => (
        <div className="text-center d-flex flex-column">
          <span className="text-capitalize">{row?.depositAccountName}</span>
          <span className="text-capitalize">{row?.depositAccountNumber}</span>
        </div>
      )
    },
    {
      Header: "정산 대상 금액",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.settlementTargetAmount}</span>
      )
    },
    {
      Header: "실제 입금액",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.settlementAmount}</span>
      )
    },
    {
      Header: "승인 여부",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.objection?.processAt && row?.status
            ? mapStatusToKo[row?.status]
            : mapStatusToKo["pending"]}
        </span>
      )
    },
    {
      Header: "이의 처리 일자",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.objection?.processAt
            ? moment(row.objection?.processAt).format("YYYY-MM-DD")
            : "-"}
        </span>
      )
    },
    {
      Header: "이의관리",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => dispatch(openDialog({ type: "complain", data: row }))}
        >
          관리
        </button>
      )
    }
  ];

  const solveComplains = async (id) => {
    await dispatch(solveComplain(id));
    window.location.reload();
  };

  return (
    <div className="mainCategory">
      <Title
        name={
          state?.state?.data?.storeId
            ? `스토어바 ${
                state ? state?.state?.data?.storeId : ""
              }역점 이의 관리`
            : "전체 매장 이의 관리"
        }
      />

      <div className="mb-4 inputData">
        <label>주차 설정</label>
        <DateRange
          serverSearching={({ startDate, endDate }) => {
            if (
              startDate !== searchDate.startDate ||
              endDate !== searchDate.endDate
            ) {
              setPage(0);
            }
            setSearchDate({ startDate, endDate });
          }}
        />
      </div>
      <div>
        <Table
          data={data}
          mapData={mapTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"server"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
      {dialogue && dialogueType === "complain" && (
        <ComplainDialog refetch={fetchData} />
      )}
    </div>
  );
};

export default Complain;
