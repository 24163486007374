import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../component/api/axiosApi";
import { DangerRight, Success } from "../../component/api/toastServices";
import { genQueryString } from "./../../util/fuction";
import { SuccessStatusCode } from "./../../util/constants";

const initialState = {
  complain: [],
  isLoading: false,
  isSkeleton: false,
  total: null
};

export const getComplains = createAsyncThunk(
  `user/complain/getComplains`,
  async (payload) => {
    return apiInstance.get(
      genQueryString("admin/reservations/settlement/objection", payload)
    );
  }
);

export const solveComplain = createAsyncThunk(
  `user/complain/solveComplain`,
  async (payload) => {
    return apiInstance.put(
      `admin/reservations/settlement/objection/approve`,
      payload
    );
  }
);

export const rejectComplain = createAsyncThunk(
  `user/complain/rejectComplain`,
  async (payload) => {
    return apiInstance.put(
      `admin/reservations/settlement/objection/reject`,
      payload
    );
  }
);

const categorySlice = createSlice({
  name: "categorySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getComplains.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getComplains.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.total = action?.payload?.total;
      state.complain = action?.payload?.data;
    });

    builder.addCase(getComplains.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(solveComplain.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(solveComplain.fulfilled, (state, action) => {
      if (action.payload.statusCode === SuccessStatusCode) {
        Success("Complain Solved Succefully");
      }
      state.isLoading = false;
    });

    builder.addCase(solveComplain.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(rejectComplain.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(rejectComplain.fulfilled, (state, action) => {
      if (action.payload.statusCode === SuccessStatusCode) {
        Success("Complain Rejected Succefully");
      }
      state.isLoading = false;
    });

    builder.addCase(rejectComplain.rejected, (state, action) => {
      state.isSkeleton = false;
    });
  }
});
export default categorySlice.reducer;
