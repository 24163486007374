import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confirm, warning } from "../../../util/Alert";
import {
  activesalon,
  getAllSalons,
  salonDelete
} from "../../../redux/slice/salonSlice";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "./../../extras/Table";
import ServiceDialogue from "./ServiceDialogue";
import { convertToTime } from "../../../util/fuction";
import { deleteService } from "../../../redux/slice/serviceSlice";

const typeSearch = [
  {
    value: "title",
    text: "제목"
  },
  {
    value: "start",
    text: "적용 시작일"
  },
  {
    value: "end",
    text: "적용 종료일"
  }
];

const mapTypeToKo = {
  SIDE_DISH: "안주"
};

const handleDataTable = (data = []) => {
  const rows = [];
  data.forEach((element, idx) => {
    const options = element.items || [];
    options.forEach((option, index) => {
      const { hours, minutes } = option.reservationAvailability
        ? convertToTime(option.reservationAvailability)
        : { hours: 0, minutes: 0 };
      if (index === 0) {
        rows.push({
          type: element.type,
          menuName: element.name,
          optionsName: option.name,
          optionsPrice: option.price,
          optionsPaymentAmount: option.settlementAmount,
          optionsReserverTime: option.reservationAvailability
            ? `${hours}시간 ${minutes ? minutes + "분" : ""}`
            : 0,
          optionsStock: option.stock,
          id: element.id,
          rowSpan: options.length,
          isRender: true,
          index: idx + 1
        });
      } else {
        rows.push({
          type: "",
          menuName: "",
          optionsName: option.name,
          optionsPrice: option.price,
          optionsPaymentAmount: option.settlementAmount,
          optionsReserverTime: option.reservationAvailability
            ? `${hours}시간 ${minutes ? minutes + "분" : ""}`
            : 0,
          optionsStock: option.stock,
          isRender: false
        });
      }
    });
  });
  return rows;
};

export const SideDishList = (props) => {
  const { service, fetchData } = props;

  const [data, setData] = useState([]);

  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleFilterDataType = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearchType(filteredData);
    } else {
      setData(filteredData);
    }
  };

  // useEffect(() => {
  //   const payload = {
  //     start: page,
  //     limit: rowsPerPage,
  //     search
  //   };
  //   dispatch(getAllSalons(payload));
  // }, [page, rowsPerPage, search]);

  useEffect(() => {
    setData(handleDataTable(service));
  }, [service]);

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  const handleInfo = (id) => {
    // navigate("/admin/salon/salonProfile", {
    //   state: {
    //     id
    //   }
    // });
  };

  const handleOpenMenu = (row) => {
    navigate("/admin/store/menu", {
      state: {
        row
      }
    });
  };

  const serviceTable = [
    {
      Header: "No",
      Cell: ({ row }) => <span>{row?.index}</span>
    },
    {
      Header: "유형",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.type ? mapTypeToKo[row?.type] : ""}
        </span>
      )
    },
    {
      Header: "메뉴 이름",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.menuName || "-"}</span>
      )
    },
    {
      // tdClass: "colOpitonName",
      Header: "옵션 이름",
      Cell: ({ row }) => (
        <span
          className="text-capitalize"
          style={{
            minWidth: "4000px"
          }}
        >
          {row?.optionsName || "-"}
        </span>
      )
    },

    {
      Header: "가격",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.optionsPrice}원</span>
      )
    },
    {
      Header: "정산금액",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.optionsPaymentAmount}원</span>
      )
    },
    {
      Header: "재고",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.optionsStock} 개</span>
      )
    },
    {
      Header: "수정",
      isMerger: true,
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenEdit(row.id)}
        >
          수정
        </button>
      )
    },
    {
      Header: "삭제",
      isMerger: true,
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleDelete(row.id)}
        >
          삭제
        </button>
      )
    }
  ];

  const handleDelete = (id) => {
    // const data = warning("Delete");
    const data = confirm({
      title: "메뉴 삭제",
      text: "해당 메뉴를 삭제하시겠습니까?",
      confirm: "확인",
      cancle: "취소"
    });
    data
      .then(async (logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          await dispatch(deleteService(id));
          setTimeout(() => {
            fetchData();
          }, 0);
        }
      })
      .catch((err) => console.log(err));
  };

  function handleOpenEdit(id) {
    const data = service.find((item) => item.id === id);
    dispatch(openDialog({ type: "serviceAdd", data }));
  }

  const handleOpenIssue = (row) => {
    dispatch(openDialog({ type: "couponIssue", data: row }));
  };

  return (
    <div className="userTable">
      <div
        className="title text-capitalized fw-600"
        style={{ color: "#686b71" }}
      >
        안주 등록
      </div>

      <div className="mt-2">
        <Table
          data={data}
          mapData={serviceTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"client"}
        />
      </div>
      {dialogue && dialogueType === "serviceAdd" && (
        <ServiceDialogue refecth={fetchData} />
      )}
    </div>
  );
};
