/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */

import { openDialog } from "../../redux/slice/dialogueSlice";
import { getPayout } from "../../redux/slice/payoutSlice";
import { warning } from "../../util/Alert";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Title from "../extras/Title";
import Button from "../extras/Button";
import Table from "../extras/Table";
import Pagination from "../extras/Pagination";

import { getSalary, payment } from "../../redux/slice/salarySlice";
import BonusPenaltyDialog from "./BonusPenaltyDialog";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Analytics from "../extras/Analytics";
import DateRange from "../extras/DateRange";
import PaymentDetailModal from "./PaymentDetailModal";
import BatchModal from "./BatchModal";
import SettlementInforModal from "./SettlementInforModal";
import { getStoreBookingSettlement } from "../../redux/slice/salonSlice";
import * as XLSX from "xlsx";
import { apiInstance } from "../api/axiosApi";
import { genQueryString } from "./../../util/fuction";

const statusSearch = [
  {
    value: "",
    text: "전체"
  },
  {
    value: "pending",
    text: "미승인"
  },
  {
    value: "rejected",
    text: "거절"
  },
  {
    value: "approved",
    text: "승인"
  },
  {
    value: "completed",
    text: "완료"
  }
];

const mapStatusToKo = {
  pending: "미승인",
  rejected: "거절",
  approved: "승인",
  completed: "완료"
};

const typeSearch = [
  {
    value: "applicantName",
    text: "이름"
  },
  {
    value: "store",
    text: "매장 "
  }
];

export const Searching = (props) => {
  const [status, setStatus] = useState("");
  const [searchType, setSearchType] = useState("");
  const [string, setString] = useState("");
  const { typeSearch, statusSearch, handleSearch, isAllPage } = props;

  const handleSearchButton = () => {
    handleSearch(status, searchType, string);
  };

  return (
    <>
      <div className="">
        <div className="inputData inputDataSearch d-flex gap-4">
          <div className="d-flex flex-column inputData">
            <label>정산 상태</label>
            <select
              value={status}
              className="inputTypeSearch"
              style={{
                height: "40px",
                marginTop: "0",
                width: "110px",
                minWidth: "110px"
              }}
              placeholder="선택"
              onChange={(e) => setStatus(e.target.value)}
            >
              {statusSearch.map((item) => (
                <option value={item.value} key={item.text}>
                  {item.text}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex flex-column inputData">
            <label>검색</label>

            {isAllPage ? (
              <select
                value={searchType}
                className="inputTypeSearch"
                style={{
                  height: "40px",
                  marginTop: "0",
                  width: "110px",
                  minWidth: "110px"
                }}
                placeholder="선택"
                onChange={(e) => setSearchType(e.target.value)}
              >
                <option value={""} disabled>
                  {"선택"}
                </option>
                {typeSearch.map((item) => (
                  <option value={item.value} key={item.text}>
                    {item.text}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="search"
                id="search"
                // placeholder="Searching for..."
                className="bg-none m0-top w-100"
                style={{ fontWeight: "500", height: "40px" }}
                value={"이름"}
                disabled
                // onChange={(e) => setSearch(e.target.value)}
              />
            )}
          </div>
          <div className="d-flex flex-column inputData">
            <label>&nbsp;</label>
            <input
              type="search"
              id="search"
              // placeholder="Searching for..."
              className="bg-none m0-top w-100"
              style={{ fontWeight: "500", height: "40px" }}
              onChange={(e) => setString(e.target.value)}
            />
          </div>
          <button
            className="py-1 bg-button-search mt-auto"
            style={{
              borderRadius: "8px",
              height: "40px",
              width: "150px"
            }}
            onClick={() => handleSearchButton()}
          >
            검색
          </button>
        </div>
      </div>
    </>
  );
};

const StoreSettlementInfo = () => {
  const getCurrentMonthDates = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based

    const startOfMonth = `${currentYear}-${currentMonth
      .toString()
      .padStart(2, "0")}-01`;
    const lastDay = new Date(currentYear, currentMonth, 0).getDate();
    const endOfMonth = `${currentYear}-${currentMonth
      .toString()
      .padStart(2, "0")}-${lastDay}`;

    return { startOfMonth, endOfMonth };
  };

  const state = useLocation();
  const [data, setData] = useState([]);

  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const { bookingSettlement, total } = useSelector((state) => state.salon);
  const { startOfMonth, endOfMonth } = getCurrentMonthDates();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState({
    type: "",
    status: "",
    text: ""
  });

  const [searchDate, setSearchDate] = useState({
    startDate: "",
    endDate: ""
  });
  const navigate = useNavigate();
  const thisMonth = new Date();
  thisMonth.setDate(1);
  const [selectedDate, setSelectedDate] = useState(thisMonth);
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
      from: searchDate.startDate
        ? new Date(searchDate.startDate).toISOString()
        : "",
      to: searchDate.endDate ? new Date(searchDate.endDate).toISOString() : "",
      status: search.status,
      applicantName:
        search.type === "applicantName" || state?.state?.row?.storeId
          ? search.text
          : "",
      storeName: search.type === "store" ? search.text : "",
      storeId: state?.state?.row?.storeId
        ? state?.state?.row?.storeId?.toString()?.trim()
        : ""
    };
    dispatch(getStoreBookingSettlement(payload));
  }, [
    dispatch,
    page,
    rowsPerPage,
    search.status,
    search.text,
    search.type,
    searchDate.endDate,
    searchDate.startDate,
    state?.state?.row?.storeId
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setData(bookingSettlement);
  }, [bookingSettlement]);

  const sumSettlementTargetAmount = useMemo(() => {
    return data.reduce(
      (sum, currItem) => sum + currItem.settlementTargetAmount,
      0
    );
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleInfo = (row) => {
    navigate("/admin/user/userProfile", {
      state: {
        row
      }
    });
  };

  const mapData = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },

    {
      Header: "이름",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleInfo(row?.applicant)}
        >
          {row?.applicant?.nickname}
        </button>
        // <div className="d-flex"></div>
      )
    },
    ...(!state?.state?.row?.storeId
      ? [
          {
            Header: "매장",
            Cell: ({ row }) => (
              <button
                className="py-1 text-decoration-underline"
                // style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
                style={{ backgroundColor: "#fff" }}
                onClick={() => handleOpenPaymentModal(row?._id)}
              >
                {row?.store?.name}
              </button>
            )
          }
        ]
      : []),
    {
      Header: "전화번호",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.applicant?.phoneNumber}</span>
      )
    },
    {
      Header: "예약 번호",
      Cell: ({ row }) => <span className="text-capitalize">{row?.id}</span>
    },
    {
      Header: `예약일`,
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.reservationDate &&
            moment(row.reservationDate).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: `입금 계좌`,
      Cell: ({ row }) => (
        <div className="text-center d-flex flex-column">
          <span className="text-capitalize">{row?.depositAccountName}</span>
          <span className="text-capitalize">{row?.depositAccountNumber}</span>
        </div>
      )
    },

    {
      Header: "정산 대상 금액",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.settlementTargetAmount}</span>
      )
    },
    {
      Header: `정산 금액`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.settlementAmount}</span>
      )
    },
    {
      Header: `정산 여부`,
      Cell: ({ row }) => (
        <div className="d-flex flex-column">
          <span>{row?.status ? mapStatusToKo[row?.status] : ""}</span>
          {(row?.status === "pending" || row?.status === "approved") && (
            <div className="d-flex align-items-center justify-content-center gap-2">
              {row?.status === "pending" && (
                <button
                  className="py-1"
                  style={{ backgroundColor: "#e0f0ff", borderRadius: "8px" }}
                  // onClick={() => handleDelete(row?._id)}
                >
                  승인
                </button>
              )}
              <button
                className="py-1"
                style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
                // onClick={() => handleDelete(row?._id)}
              >
                완료
              </button>
            </div>
          )}
        </div>
      )
    },
    {
      Header: `처리 일자`,
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.processingDate
            ? moment(row.processingDate).format("YYYY-MM-DD")
            : "-"}
        </span>
      )
    },
    {
      Header: `결제 상세`,
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenPaymentModal(row)}
        >
          결제상세
        </button>
      )
    }
  ];

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePayment = (row) => {
    const payload = {
      settlementId: row?._id
    };
    dispatch(payment(payload));
  };

  const handleEarning = (row) => {
    // navigate("/admin/salon/income", {
    //   state: {
    //     row,
    //   },
    // });
  };

  const handleInfoSettlement = (row) => {
    // navigate("/admin/salon/particularSettlement", {
    //   state: {
    //     row,
    //   },
    // });
  };

  const handleOpenBatchApproveModal = () => {
    const listNotApprove = data.filter((item) => item.status === "pending");
    dispatch(openDialog({ type: "batchApprove", data: listNotApprove }));
  };

  const handleOpenBatchCompleteModal = () => {
    const listNotComplete = data.filter((item) => item.status === "approved");
    dispatch(openDialog({ type: "batchComplete", data: listNotComplete }));
  };

  const handleOpenPaymentModal = (row) => {
    dispatch(openDialog({ type: "paymentDetail", data: row }));
  };

  const handleOpenInfoModal = () => {
    dispatch(openDialog({ type: "settlementInfo", data: data }));
  };

  const handleSearch = (status, type, text) => {
    if (
      text !== search.text ||
      status !== search.status ||
      text !== search.text
    ) {
      setPage(0);
    }
    setSearch({
      status,
      type,
      text
    });
  };

  const handleDownloadExcel = async () => {
    const payload = {
      from: searchDate.startDate
        ? new Date(searchDate.startDate).toISOString()
        : "",
      to: searchDate.endDate ? new Date(searchDate.endDate).toISOString() : "",
      status: search.status,
      applicantName:
        search.type === "applicantName" || state?.state?.row?.storeId
          ? search.text
          : "",
      storeName: search.type === "store" ? search.text : "",
      storeId: state?.state?.row?.storeId
        ? state?.state?.row?.storeId?.toString()?.trim()
        : ""
    };
    const res = await apiInstance.get(
      genQueryString("admin/reservations/settlement", payload)
    );
    console.log("res", res);
    const flattenedData = (res?.data || []).map((item, index) => ({
      No: index + 1,
      이름: item?.applicant?.nickname,
      ...(!state?.state?.row?.storeId ? { 매장: item?.store?.name } : {}),
      전화번호: item?.applicant?.phoneNumber,
      "예약 번호": item?.id,
      예약일: item?.reservationDate
        ? moment(item.reservationDate).format("YYYY-MM-DD")
        : "",
      "입금 계좌": `${item?.depositAccountName} | ${item?.depositAccountNumber}`,
      "정산 대상 금액": item?.settlementTargetAmount,
      "정산 금액": item?.settlementAmount,
      "정산 여부": item?.status ? mapStatusToKo[item?.status] : "",
      "처리 일자": item?.processingDate
        ? moment(item.processingDate).format("YYYY-MM-DD")
        : "-"
    }));

    const headers = [
      "No",
      "이름",
      ...(!state?.state?.row?.storeId ? ["매장"] : []),
      "전화번호",
      "예약 번호",
      "예약일",
      "입금 계좌",
      "정산 대상 금액",
      "정산 금액",
      "정산 여부",
      "처리 일자"
    ];
    const ws = XLSX.utils.json_to_sheet(flattenedData);

    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let col = 0; col < headers.length; col++) {
      const cellAddress = { r: range.s.r, c: col };
      const cell = { v: headers[col], t: "s" };
      ws[XLSX.utils.encode_cell(cellAddress)] = cell;
    }

    const fileName = state?.state?.row?.storeId
      ? `스토어바 ${state?.state?.row?.storeId || ""}역점 정산 관리`
      : "예약 정산 관리";

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, fileName + ".xlsx");
  };

  return (
    <div className="mainCategory">
      {dialogue && dialogueType === "paymentDetail" && (
        <PaymentDetailModal allStore={!!!state?.state?.row?.storeId} />
      )}
      {dialogue && dialogueType === "batchApprove" && (
        <BatchModal
          title="일괄 승인"
          subTitle="일괄 승인할 금액 목록입니다."
          refetch={fetchData}
        />
      )}
      {dialogue && dialogueType === "batchComplete" && (
        <BatchModal
          title="일괄 완료"
          subTitle="일괄 완료처리할 금액 목록입니다."
          refetch={fetchData}
        />
      )}
      {dialogue && dialogueType === "settlementInfo" && (
        <SettlementInforModal />
      )}
      <Title
        name={
          state?.state?.row?.storeId
            ? `스토어바 ${state?.state?.row?.storeId || ""}역점 정산 관리`
            : "예약 정산 관리"
        }
      />
      <div className="d-flex justify-content-between mb-2">
        <Searching
          typeSearch={typeSearch}
          statusSearch={statusSearch}
          handleSearch={handleSearch}
        />
        <button
          className="py-1 bg-button-search mt-auto"
          style={{
            borderRadius: "8px",
            height: "40px",
            width: "150px"
          }}
          onClick={handleDownloadExcel}
        >
          다운로드
        </button>
      </div>
      <div className="mb-2">
        <DateRange
          serverSearching={({ startDate, endDate }) => {
            if (
              startDate !== searchDate.startDate ||
              endDate !== searchDate.endDate
            ) {
              setPage(0);
            }
            setSearchDate({ startDate, endDate });
          }}
        />
      </div>
      <div className="mb-4 inputData">
        <label>리스트 내 총 정산 금액</label>
        <div className="d-flex align-items-center gap-4">
          <div
            style={{ height: "40px" }}
            className="d-flex align-items-center justify-content-center px-4 bg-button-search rounded"
          >
            {sumSettlementTargetAmount} 원
          </div>
          <button
            className="py-1 bg-button-search mt-auto rounded"
            style={{
              height: "40px",
              width: "150px"
            }}
            onClick={() => handleOpenBatchApproveModal()}
          >
            일괄 승인
          </button>
          <button
            className="py-1 bg-button-search mt-auto rounded"
            style={{
              height: "40px",
              width: "150px"
            }}
            onClick={() => handleOpenBatchCompleteModal()}
          >
            일괄 완료
          </button>
          <button
            className="py-1 bg-button-search mt-auto rounded"
            style={{
              height: "40px",
              width: "190px"
            }}
            onClick={() => handleOpenInfoModal()}
          >
            계좌별 정산 금액
          </button>
        </div>
      </div>
      <div>
        <Table
          data={data}
          mapData={mapData}
          PerPage={rowsPerPage}
          Page={page}
          type={"server"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
    </div>
  );
};

export default StoreSettlementInfo;
