import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../extras/Title";
import Pagination from "../../extras/Pagination";
import moment from "moment";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "./../../extras/Table";
import Button from "../../extras/Button";
import SendModal from "./SendModal";
import { getAllNotification } from "../../../redux/slice/notificationSlice";
import { SearchingWithSelect } from "./../../extras/Searching";

const typeSearch = [
  {
    value: "title",
    text: "제목"
  },
  {
    value: "content",
    text: "내용"
  }
];

const mapTypeToKo = {
  all: "전체",
  user: "사용자",
  bartender: "바텐더"
};

export const NotificationList = () => {
  const [data, setData] = useState([]);

  const { notification, total } = useSelector((state) => state.notification);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    type: "",
    text: ""
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const fetchData = useCallback(() => {
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
      searchText: search.text,
      searchType: search.type
    };
    dispatch(getAllNotification(payload));
  }, [dispatch, page, rowsPerPage, search.text, search.type]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setData(notification);
  }, [notification]);

  const salonTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "발송일",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.dateSent && moment(row.dateSent).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: "제목",
      Cell: ({ row }) => <span>{row?.title}</span>
    },
    {
      Header: "내용",
      tdClass: "colContentCoupon",
      Cell: ({ row }) => <span className="truncate-line-2">{row?.content}</span>
    },
    {
      Header: "회원 타입",
      Cell: ({ row }) => (
        <span className="">
          {row?.sendType ? mapTypeToKo[row?.sendType] : ""}
        </span>
      )
    },
    {
      Header: "발송 회원수",
      Cell: ({ row }) => <span className="">{row?.totalRecipients}</span>
    }
  ];

  const handleOpenAdd = () => {
    dispatch(openDialog({ type: "sendModal", data: null }));
  };

  const handleSearch = (text, type) => {
    if (text !== search.text || type !== search.type) {
      setPage(0);
    }
    setSearch({ text, type });
  };

  return (
    <div className="userTable">
      <Title name="PUSH 알림 설정" />

      <div className="betBox mb-3">
        <div className="col-md-8 col-lg-7 inputData">
          <label>검색</label>
          <SearchingWithSelect
            typeSearch={typeSearch}
            setData={setData}
            selectPlaceholder={"선택"}
            handleSearch={handleSearch}
            button
          />
        </div>
        <Button
          className={`d-flex align-items-center bg-button p-10 text-white m10-bottom ms-auto mt-auto`}
          style={{ marginLeft: "20px", height: "40px" }}
          text={`알림 발송`}
          // bIcon={`fa-solid fa-user-plus`}
          onClick={() => handleOpenAdd()}
        />
      </div>
      <div>
        <Table
          data={data}
          mapData={salonTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"server"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
      {dialogue && dialogueType === "sendModal" && (
        <SendModal refetch={fetchData} />
      )}
    </div>
  );
};
