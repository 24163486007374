/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../component/api/axiosApi";
import { DangerRight, Success } from "../../component/api/toastServices";
import { SuccessStatusCode } from "../../util/constants";
import { genQueryString } from "../../util/fuction";

const initialState = {
  isLoading: false,
  isSkeleton: false,
  permission: [],
  total: null
};

export const getAllPermission = createAsyncThunk(
  "admin/permission/getAll",
  async (payload) => {
    return apiInstance.get(genQueryString(`admin/permission`, payload));
  }
);

export const addPermission = createAsyncThunk(
  "admin/permission/create",
  async (payload) => {
    return apiInstance.post(`admin/permission`, payload);
  }
);

export const updatePermission = createAsyncThunk(
  "admin/permission/update",
  async (payload) => {
    return apiInstance.put(`admin/permission/${payload.id}`, payload.body);
  }
);

export const deletePermission = createAsyncThunk(
  "admin/permission/delete",
  async (id) => {
    const res = await apiInstance.delete(`admin/permission/${id}`);
    return {
      data: res,
      id
    };
  }
);

const permissionSlice = createSlice({
  name: "permissionSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllPermission.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllPermission.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.permission = action.payload.data;
      state.total = action.payload.count;
    });

    builder.addCase(getAllPermission.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(addPermission.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addPermission.fulfilled, (state, action) => {
      if (action?.payload?.data?.data) {
        state.total += 1;
        Success("Permission Added Successfully");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(addPermission.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updatePermission.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updatePermission.fulfilled, (state, action) => {
      if (action?.payload?.data?.data) {
        Success("Permission Updated Successfully");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(updatePermission.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deletePermission.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deletePermission.fulfilled, (state, action) => {
      if (action?.payload?.data?.statusCode === SuccessStatusCode) {
        state.permission = state.permission.filter(
          (item) => item?.id !== action?.payload?.id
        );

        state.total -= 1;
        Success("Permission Delete Successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(deletePermission.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});
export default permissionSlice.reducer;
