/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input, { ExInput } from "../extras/Input";
import Button from "../extras/Button";
import { login } from "../../redux/slice/authSlice";
import logo from "../../assets/images/logo.png";
import { SuccessStatusCode } from "../../util/constants";
import useCustomToast from "../../hooks/useCustomToast";
import {
  getNotificationStock,
  hideNotificationStock
} from "../../redux/slice/notificationSlice";

const Login = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuth = useSelector((state) => state.auth.isAuth);
  const showCustomToast = useCustomToast();

  useEffect(() => {
    isAuth && navigate("/admin");
  }, [isAuth, navigate]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    email: "",
    password: ""
  });

  const stockListToast = useCallback(async () => {
    try {
      const res = await dispatch(getNotificationStock());
      const data = res?.payload?.data || [];
      console.log("333333333333333333", data);
      for (const item of data) {
        const toastData = {
          title: "※재고가 부족합니다.",
          storeName: item?.product?.store?.name,
          menu: item?.product?.name,
          stockRemaining: item?.product?.stock
        };

        showCustomToast(toastData);

        await dispatch(hideNotificationStock(item.id));
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  const submit = async () => {
    if (!email || !password) {
      let error = {};
      if (!email) error.email = "이메일(아이디) 항목은 필수 입니다.";
      if (!password) error.password = "비밀번호 항목은 필수 입니다.";
    } else {
      const loginData = {
        email,
        password
      };

      let response = await dispatch(login(loginData)).unwrap();
      console.log("response", response);
      if (response?.statusCode === SuccessStatusCode) {
        navigate("/admin/");
        await stockListToast();
      }
    }
  };

  return (
    <>
      <div className="mainLoginPage">
        <div className="loginDiv" style={{ width: "100%" }}>
          <div className="loginPage m-auto">
            <div className="loginTitle mb-3  d-flex ">
              <img src={logo} style={{ width: "50px" }} alt="logo" />
            </div>
            {/* <div className="fw-bold text-theme  me-auto my-auto welComeTitle">
              Welcome Back
            </div> */}
            <h1>스토어바 관리자 페이지</h1>
            {/* <h6 className="fw-bold text-theme  me-auto my-auto fs-15 py-2 title">
              Please,Enter Your Email id and Password
            </h6> */}
            <div>
              <div className="col-12 ">
                <ExInput
                  type={`text`}
                  id={`email`}
                  name={`email`}
                  label={`이메일(아이디)`}
                  value={email}
                  placeholder={`이메일(아이디)을 입력해주세요.`}
                  errorMessage={error.email && error.email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        email: `이메일(아이디) 항목은 필수 입니다.`
                      });
                    } else {
                      return setError({
                        ...error,
                        email: ""
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 ">
                <ExInput
                  type={`password`}
                  id={`password`}
                  name={`password`}
                  value={password}
                  label={`비밀번호`}
                  placeholder={`비밀번호를 입력해주세요.`}
                  errorMessage={error.password && error.password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        password: `비밀번호 항목은 필수 입니다.`
                      });
                    } else {
                      return setError({
                        ...error,
                        password: ""
                      });
                    }
                  }}
                />
              </div>
              <div className="loginButton d-flex gx-2 justify-content-center">
                <Button
                  type={`submit`}
                  className={`bg-theme text-light cursor m10-top col-6 mx-2`}
                  text={`로그인`}
                  onClick={submit}
                  style={{ borderRadius: "30px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
