/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Button from "../../extras/Button";
import Table from "../../extras/Table";
import Pagination from "../../extras/Pagination";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { closeDialog, openDialog } from "../../../redux/slice/dialogueSlice";
import Title from "../../extras/Title";
import { allUsers, blockUser } from "../../../redux/slice/userSlice";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Searching from "../../extras/Searching";
import male from "../../../assets/images/male.png";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import NotificationDialog from "./NotificationDialog";
import { SelectV2 } from "../../extras/Input";
import { apiInstance } from "../../api/axiosApi";

const typeSearch = [
  { name: "전체", value: "" },
  { name: "등록", value: "true" },
  { name: "미등록", value: "false" }
];

export const User = () => {
  const dispatch = useDispatch();
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { user, total } = useSelector((state) => state?.user);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
      isBartender: search
    };
    dispatch(allUsers(payload));
  }, [dispatch, page, rowsPerPage, search]);

  useEffect(() => {
    setData(user);
  }, [user]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  const userTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "이름",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.fullname}</span>
      )
    },
    {
      Header: "닉네임",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.nickname}</span>
      )
    },
    {
      Header: "휴대폰 번호",
      Cell: ({ row }) => <span>{row?.phoneNumber}</span>
    },
    {
      Header: "보유 포인트",
      Cell: ({ row }) => <span>{row?.pointBalance || 0}p</span>
    },
    {
      Header: "바텐더 등록 여부",
      Cell: ({ row }) => <span>{row?.isBartender ? "등록" : "미등록"}</span>
    },
    {
      Header: "프로필 상세",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ borderRadius: "8px", backgroundColor: "#E0F0FF" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleInfo(row)}
        >
          프로필 보기
        </button>
      )
    }
  ];

  const handleInfo = (row) => {
    navigate("/admin/user/userProfile", {
      state: {
        row
      }
    });
  };

  const handleProfile = (row, type) => {
    dispatch(openDialog({ type: type, data: row }));
    localStorage.setItem(
      "dialog",
      JSON.stringify({ dialogue: true, type: type, data: row })
    );
  };
  const handleNotify = (id) => {
    dispatch(openDialog({ type: "notification", data: { id, type: "user" } }));
  };

  const handleStatus = (id) => {
    dispatch(blockUser(id));
  };

  const handleDownloadPDF = () => {
    const flattenedData = data.map((user, index) => ({
      No: index + 1,
      Name: user?.fname + " " + user?.lname,
      UniqueId: user?.uniqueId,
      MobileNo: user?.mobile,
      Email: user?.email
    }));

    const headers = ["No", "Name", "UniqueId", "MobileNo", "Email"];
    const doc = new jsPDF({
      orientation: "landscape"
    });
    doc.text("User Data", 10, 10);
    doc.autoTable({
      head: [headers],
      body: flattenedData?.map((user) => Object?.values(user))
    });
    doc.save("user_data.pdf");
  };

  const handleDownloadCSV = () => {
    const flattenedData = data.map((user, index) => ({
      No: index + 1,
      Name: user?.fname + " " + user?.lname,
      UniqueId: user?.uniqueId,
      MobileNo: user?.mobile,
      Email: user?.email
    }));

    const headers = ["No", "Name", "UniqueId", "MobileNo", "Email"];
    const csvContent = Papa.unparse({
      fields: headers,
      data: flattenedData
    });
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL?.createObjectURL(blob);
    const link = document?.createElement("a");
    link.href = url;
    link.setAttribute("download", "user_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadExcel = async () => {
    const res = await apiInstance.get(
      `admin/user${search ? `?isBartender=${search}` : ""}`
    );
    console.log("res", res);
    const flattenedData = (res?.data || []).map((item, index) => ({
      No: index + 1,
      이름: item?.fullname,
      닉네임: item?.nickname,
      "휴대폰 번호": item?.phoneNumber,
      "보유 포인트": (item?.pointBalance || 0) + "p",
      "바텐더 등록 여부": item?.isBartender ? "등록" : "미등록"
    }));

    const headers = [
      "No",
      "이름",
      "닉네임",
      "휴대폰 번호",
      "보유 포인트",
      "바텐더 등록 여부"
    ];
    const ws = XLSX.utils.json_to_sheet(flattenedData);

    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let col = 0; col < headers.length; col++) {
      const cellAddress = { r: range.s.r, c: col };
      const cell = { v: headers[col], t: "s" };
      ws[XLSX.utils.encode_cell(cellAddress)] = cell;
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "회원 리스트");
    XLSX.writeFile(wb, "회원 리스트.xlsx");
  };

  const handlePrint = () => {
    const flattenedData = data?.map((user, index) => ({
      No: index + 1,
      Name: user?.fname + " " + user?.lname,
      UniqueId: user?.uniqueId,
      MobileNo: user?.mobile,
      Email: user?.email
    }));

    const headers = ["No", "Name", "UniqueId", "MobileNo", "Email"];
    const doc = new jsPDF({
      orientation: "landscape"
    });
    doc.text("User Data", 10, 10);
    doc.autoTable({
      head: [headers],
      body: flattenedData.map((user) => Object.values(user))
    });
    doc.autoPrint();
    window.open(doc.output("bloburl"), "_blank");
  };

  const handleOpenBookings = (row) => {
    navigate("/admin/user/bookings", { state: { data: row } });
  };

  return (
    <div className="mainCategory">
      {dialogue && dialogueType === "notification" && (
        <div className="userTable">
          <NotificationDialog />
        </div>
      )}
      <div
        className="userTable"
        style={{
          display: `${dialogueType === "userHistory" ? "none" : "block"}`
        }}
      >
        <Title name="회원 리스트" />
        <div className="betBox mb-4">
          <div className="col-2 inputData">
            <label>바텐더 등록 여부</label>

            <SelectV2
              id={`typeSearch`}
              option={typeSearch}
              btnClass={`mt-0`}
              onChange={(value) => {
                setPage(0);
                setSearch(value);
              }}
            />
          </div>
          <div className="ms-auto mt-auto mb-2">
            <Button
              className="bg-button-excel text-black p5-y"
              onClick={handleDownloadExcel}
              text={`다운로드`}
            />
          </div>
        </div>
        <div>
          <Table
            data={data}
            mapData={userTable}
            PerPage={rowsPerPage}
            Page={page}
            type={"server"}
          />
          <Pagination
            type={"client"}
            serverPage={page}
            setServerPage={setPage}
            serverPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            totalData={total}
          />
        </div>
      </div>
    </div>
  );
};
