// status code
export const SuccessStatusCode = 200;
export const UnauthorizedStatusCode = 401;

// cs
export const CsInquiryType = {
  APP_INQUIRY: "앱 문의",
  STORE_USE: "매장 이용",
  PAYMENT: "결제",
  OTHERS: "기타"
};
export const CsInquiryStatus = {
  COMPLETED: "처리 완료",
  PENDING: "대기중"
};

// leader
export const classificationLeader = {
  MANAGEMENT: "관리팀장",
  MARKETING: "영업팀장"
};
