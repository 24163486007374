import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../component/api/axiosApi";
import { Success } from "../../component/api/toastServices";
import axios from "axios";
import { SuccessStatusCode } from "../../util/constants";
import { genQueryString } from "../../util/fuction";

const initialState = {
  notification: [],
  total: null,
  isLoading: false,
  isSkeleton: false
};
export const userNotification = createAsyncThunk(
  "admin/notification/toOneUser",
  async (payload) => {
    return apiInstance.post(
      `admin/notification/toOneUser?userId=${payload?.userId}`,
      payload?.data
    );
  }
);

export const expertNotification = createAsyncThunk(
  "admin/notification/toExpert",
  async (payload) => {
    return apiInstance.post(
      `admin/notification/toExpert?expertId=${payload?.expertId}`,
      payload?.data
    );
  }
);

export const allUserNotification = createAsyncThunk(
  "admin/notification/notifyAllUsers",
  async (payload) => {
    return axios.post("admin/notification/notifyAllUsers", payload);
  }
);

export const getAllNotification = createAsyncThunk(
  "admin/notification/all",
  async (payload) => {
    return apiInstance.get(genQueryString("admin/notifications", payload));
  }
);

export const getNotificationStock = createAsyncThunk(
  "admin/notification/getNotificationStock",
  async () => {
    return apiInstance.get("admin/notifications/alarms/inventory");
  }
);

export const hideNotificationStock = createAsyncThunk(
  "admin/notification/hideNotificationStock",
  async (id) => {
    return apiInstance.post(`admin/notifications/click/alarm/${id}`);
  }
);

export const createNotification = createAsyncThunk(
  "admin/notification/create",
  async (payload) => {
    return apiInstance.post(`admin/notifications`, payload);
  }
);

const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userNotification.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(userNotification.fulfilled, (state, action) => {
      if (action?.payload.status) {
        state.notification = action?.payload?.notification;
        Success("Notification Send SuccessFully");
      }
      state.isLoading = false;
    });

    builder.addCase(userNotification.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(expertNotification.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(expertNotification.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.notification = action?.payload?.notification;
        Success("Notification Send SuccessFully");
      }
      state.isLoading = false;
    });

    builder.addCase(expertNotification.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(allUserNotification.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(allUserNotification.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        Success("Notification Send SuccessFully");
      }
      state.isLoading = false;
    });

    builder.addCase(allUserNotification.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getAllNotification.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getAllNotification.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        state.notification = action?.payload?.data;
        state.total = action?.payload?.count;
      }
      state.isLoading = false;
    });

    builder.addCase(getAllNotification.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createNotification.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(createNotification.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        Success("Notification Send SuccessFully");
      }
      state.isLoading = false;
    });

    builder.addCase(createNotification.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});

export default notificationSlice.reducer;
