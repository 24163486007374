import React, { useEffect, useRef, useState } from "react";
import { Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import { useLocation } from "react-router-dom";
import { getSalonDetail, updateSalon } from "../../../redux/slice/salonSlice";
import { SuccessStatusCode } from "../../../util/constants";

const SetMenuDescriptionModal = () => {
  const dispatch = useDispatch();
  const loadingRef = useRef(false);
  const [set, setSet] = useState("");
  const [daySet, setDaySet] = useState("");
  const [single, setSingle] = useState("");
  const { salonDetail } = useSelector((state) => state.salon);
  const [error, setError] = useState({
    text: ""
  });

  const state = useLocation();

  console.log("state", state);

  useEffect(() => {
    if (state?.state?.row?.storeId) {
      dispatch(getSalonDetail(state?.state?.row?.storeId));
    }
  }, [dispatch, state]);

  useEffect(() => {
    if (state?.state?.row?.storeId === salonDetail?.storeId) {
      setDaySet(salonDetail?.daytimeMenuDescription);
      setSet(salonDetail?.setMenuDescription);
      setSingle(salonDetail?.singleMenuDescription);
    }
  }, [state, salonDetail]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (state?.state?.row?.storeId) {
      loadingRef.current = true;
      const body = {
        setMenuDescription: set,
        daytimeMenuDescription: daySet,
        singleMenuDescription: single
      };
      const payload = { storeId: state?.state?.row?.storeId, formData: body };
      const res = await dispatch(updateSalon(payload));
      console.log("res update", res);
      if (res?.payload?.statusCode === SuccessStatusCode) {
        dispatch(closeDialog());
        loadingRef.current = false;
      }
    }
    loadingRef.current = false;
  };

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-md-7 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">메뉴 설명 설정</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="col-12">
                  <Textarea
                    value={set}
                    row={3}
                    onChange={(e) => setSet(e.target.value)}
                    label={`세트`}
                    placeholder={"설명을 입력하세요"}
                  />
                </div>
                <div className="col-12">
                  <Textarea
                    value={daySet}
                    row={3}
                    onChange={(e) => setDaySet(e.target.value)}
                    label={`낮 세트`}
                    placeholder={"설명을 입력하세요"}
                  />
                </div>
                <div className="col-12">
                  <Textarea
                    value={single}
                    row={3}
                    onChange={(e) => setSingle(e.target.value)}
                    label={`단품`}
                    placeholder={"설명을 입력하세요"}
                  />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-center align-items-center gap-2">
                <Button
                  className={`bg-gray text-light`}
                  text={`취소`}
                  type={`button`}
                  onClick={() => dispatch(closeDialog())}
                />
                <Button
                  type={`submit`}
                  className={`text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  disabled={loadingRef.current}
                  text={`등록`}
                  onClick={(e) => handleSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetMenuDescriptionModal;
