/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Title from "../../extras/Title";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  approveBartenderRequest,
  getRequest,
  getUser,
  rejectBartenderRequest
} from "../../../redux/slice/userSlice";
import { useState } from "react";
import { ExInput, Textarea } from "../../extras/Input";
import Button from "../../extras/Button";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Male from "../../../assets/images/male.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { isLoading } from "../../../util/allSelector";

const BartenderProfile = () => {
  const { userProfile, userRequest } = useSelector((state) => state.user);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const loader = useSelector(isLoading);

  useEffect(() => {
    if (state?.row?.id) {
      dispatch(getUser(state?.row?.id));
    }
    if (state?.row?.requestId) {
      dispatch(getRequest(state?.row?.requestId));
    }
  }, [dispatch, state]);

  useEffect(() => {
    if (
      state?.row?.requestId === userRequest.requestId &&
      state?.row?.id === userProfile.id
    ) {
      setData(userRequest);
    }
  }, [userRequest, userProfile, state]);

  const handelPreviousPage = () => {
    if (state) {
      navigate(-1);
    } else {
      localStorage.removeItem("dialogueData");
      dispatch({ type: closeDialog });
    }
  };

  const snsAddressLink = (name) => {
    const snsAddress = data?.snsAddress || [];
    return snsAddress.find((item) => item.name === name)?.address || "";
  };

  const handleApproveRequest = async () => {
    try {
      // loadingRef.current = true;
      setIsLoadingSubmit(true);
      await dispatch(approveBartenderRequest(state?.row?.requestId));
      // loadingRef.current = false;
      setIsLoadingSubmit(false);
    } catch (error) {
      // loadingRef.current = false;
      setIsLoadingSubmit(false);
      console.log(error);
    }
  };

  const handleRejectRequest = async () => {
    try {
      // loadingRef.current = true;
      setIsLoadingSubmit(true);
      await dispatch(rejectBartenderRequest(state?.row?.requestId));
      // loadingRef.current = false;
      setIsLoadingSubmit(false);
    } catch (error) {
      // loadingRef.current = false;
      setIsLoadingSubmit(false);
      console.log(error);
    }
  };

  return (
    <div className="userProfile focusNone">
      {loader === true ? (
        <>
          <SkeletonTheme baseColor="#e2e5e7" highlightColor="#fff">
            <div className="col-xl-2 col-md-3 col-sm-4">
              <Skeleton height={30} />
            </div>
          </SkeletonTheme>
        </>
      ) : (
        <Title
          name={`${data?.fullname ? data?.fullname : ""} 바텐더 등록 신청`}
        />
      )}
      <div className="col-7 my-auto ms-auto justify-content-end d-flex pe-3">
        <Button
          className={`bg-danger  text-center text-white mt-3`}
          onClick={handelPreviousPage}
          style={{
            borderRadius: "5px"
          }}
          bIcon={`fa-solid fa-angles-left text-white fs-20 m-auto`}
          text="뒤로가기"
        />
      </div>
      <div className="boxShadow p-4">
        <div
          className="position-relative  rounded-4 px-4 w-100"
          style={{ height: "180px", backgroundColor: "#1C2B20" }}
        >
          <div className="bg-theme w-100">
            {loader === true ? (
              <>
                <SkeletonTheme baseColor="#e2e5e7" highlightColor="#fff">
                  <p className="d-flex justify-content-center ">
                    <Skeleton
                      className="mx-auto  position-absolute"
                      style={{
                        width: 160,
                        height: 160,
                        objectFit: "cover",
                        borderRadius: "50%",
                        top: "88px",
                        left: "50px",
                        backgroundColor: "#FFFFFF",
                        padding: "5px"
                      }}
                    />
                  </p>
                </SkeletonTheme>
              </>
            ) : (
              <img
                src={data?.avatar ? data.avatar : Male}
                onError={(e) => {
                  e.target.src = Male;
                }}
                alt="User"
                className="mx-auto  position-absolute"
                style={{
                  width: 160,
                  height: 160,
                  objectFit: "cover",
                  borderRadius: "50%",
                  top: "88px",
                  left: "50px",
                  backgroundColor: "#FFFFFF",
                  padding: "5px"
                }}
              />
            )}
          </div>
        </div>
        <div className="row" style={{ marginTop: "100px" }}>
          {loader === true ? (
            <>
              <SkeletonTheme baseColor="#e2e5e7" highlightColor="#fff">
                {[1, 2, 3, 4].map((index) => (
                  <div key={index} className="col-xl-4 col-md-6 col-sm-12">
                    <Skeleton height={40} className="mt-3" />
                  </div>
                ))}
              </SkeletonTheme>
            </>
          ) : (
            <>
              <div className="justify-content-end align-items-center d-flex w-100">
                <Button
                  className={`bg-gray d-flex align-items-center text-light`}
                  style={{
                    height: "40px"
                  }}
                  text={`거절`}
                  type={`button`}
                  disabled={isLoadingSubmit}
                  onClick={() => handleRejectRequest()}
                />
                <Button
                  className={`text-light d-flex align-items-center ms-4`}
                  style={{
                    height: "40px",
                    backgroundColor: "#70AD47"
                  }}
                  text={`승인`}
                  type={`button`}
                  disabled={isLoadingSubmit}
                  onClick={() => handleApproveRequest()}
                />
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ExInput
                        type={`text`}
                        value={data?.fullname}
                        label={`이름`}
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <ExInput
                        type={`text`}
                        value={data?.phoneNumber}
                        label={`전화번호`}
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ExInput
                        type={`text`}
                        value={data?.nickname}
                        label={`닉네임`}
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <ExInput
                        type={`text`}
                        value={(data?.pointBalance || 0) + "p"}
                        label={`포인트`}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4 d-flex flex-column">
                  <div className="row">
                    <div className="col-12">
                      <ExInput
                        type={`text`}
                        value={snsAddressLink("YOUTUBE")}
                        label={`소개 sns`}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <ExInput
                        type={`text`}
                        value={snsAddressLink("INSTAGRAM")}
                        label={``}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <ExInput
                        type={`text`}
                        value={snsAddressLink("BLOG")}
                        label={``}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <div className="row">
                    <div className="col-sm-12 d-flex flex-column inputData">
                      <label>해시태그</label>
                      {(data?.hashtags || []).map((item) => (
                        <ExInput
                          type={`text`}
                          value={item || ""}
                          label={``}
                          readOnly={true}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-8 inputData">
                  <label>소개글</label>
                  <Textarea
                    value={data?.introduce}
                    row={5}
                    disabled
                    label={``}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 d-flex flex-column inputData">
                  <label>사진</label>
                  <div className="col-12 d-flex">
                    {data?.photos?.length > 0 && (
                      <>
                        {data?.photos?.map((file, index) => {
                          return (
                            <div key={index}>
                              <img
                                height="60px"
                                width="60px"
                                alt="representative"
                                src={file}
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  boxShadow:
                                    "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                  border: "2px solid #fff",
                                  borderRadius: 10,
                                  float: "left",
                                  marginRight: 15
                                }}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="col-md-6 col-sm-12">
                <ExInput
                  type={`text`}
                  value={data?.gender}
                  label={`Gender`}
                  readOnly={true}
                />
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12">
                <ExInput
                  type={`text`}
                  value={data?.age ? data?.age : "-"}
                  label={`Age`}
                  readOnly={true}
                />
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12">
                <ExInput
                  type={`text`}
                  value={data?.email ? data?.email : "-"}
                  label={`Email Id`}
                  readOnly={true}
                />
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12">
                <ExInput
                  type={`text`}
                  value={
                    data?.loginType == 1
                      ? "Email Login"
                      : data?.loginType == 2
                      ? "Google Login"
                      : "Mobile Login"
                  }
                  label={`Login Type`}
                  readOnly={true}
                  placeholder={`Login Type`}
                />
              </div> */}
            </>
          )}
        </div>
        {/* <div className="row">
          <div className="col-12 inputData">
            {loader === true ? (
              <>
                <SkeletonTheme
                  baseColor="#e2e5e7"
                  highlightColor="#fff"
                  className="mb-5 mt-3"
                >
                  <Skeleton className="mt-5" height={142} />
                </SkeletonTheme>
              </>
            ) : (
              <>
                <label>Bio</label>
                <textarea
                  value={data?.bio ? data?.bio : "-"}
                  readOnly
                  style={{ width: "100%", resize: "none" }}
                  cols="30"
                  rows={5}
                />
              </>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BartenderProfile;
