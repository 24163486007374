import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Login from "./component/pages/Login";
import { Route, Routes } from "react-router-dom";
import Admin from "./component/pages/Admin";
import AuthRoute from "./util/authRoute";
import "../src/assets/scss/custom/custom.css";
import "../src/assets/scss/default/default.css";
import "../src/assets/scss/style/style.css";
import "../src/assets/scss/dateRange.css";
import { getAdmin, setOldAdmin } from "./redux/slice/authSlice";
import { setToken } from "./util/setAuth";
function App() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { isAuth } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuth) {
      dispatch(getAdmin());
    }
    //   dispatch(getSetting());
  }, [dispatch, isAuth]);

  useEffect(() => {
    if (!token) return;
    dispatch(setOldAdmin(token));
  }, [setToken, token, isAuth]);

  return (
    <>
      <div className="App">
        <Routes>
          {/* <Route path="/" element={login ? <Login /> : <Registration />} /> */}

          {/* <Route path="/code" element={<UpdateCode />} /> */}
          {/* {login && <Route path="/login" element={<Login />} />} */}
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route element={<AuthRoute />}>
            <Route path="/admin/*" element={<Admin />} />
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
