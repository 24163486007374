/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../component/api/axiosApi";
import jwt_decode from "jwt-decode";
import { SetDevKey, setToken } from "../../util/setAuth";
import { secretKey } from "../../util/config";
import { DangerRight, Success } from "../../component/api/toastServices";
import axios from "axios";
import { SuccessStatusCode } from "../../util/constants";

const initialState = {
  admin: {},
  isAuth: false,
  isLoading: false
};
export const signUp = createAsyncThunk("admin/signUp", async (payload) => {
  return apiInstance.post("admin/signUp", payload);
});

export const updateCode = createAsyncThunk(
  "admin/updateCode",
  async (payload) => {
    return apiInstance.patch("admin/updateCode", payload);
  }
);

export const login = createAsyncThunk("admin/auth/login", async (payload) => {
  return apiInstance.post("admin/auth/login", payload);
});

export const getAdmin = createAsyncThunk("admins/profile", async () => {
  return apiInstance.get("admins/profile");
});

export const updateAdmin = createAsyncThunk("admin/update", async (payload) => {
  return apiInstance.patch("admin/update", payload);
});

export const updateAdminPassword = createAsyncThunk(
  "admin/updatePassword",
  async (payload) => {
    return apiInstance.put("admin/updatePassword", payload);
  }
);

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setOldAdmin(state, action) {
      let token = action.payload;
      state.admin = token;
      state.isAuth = true;
      SetDevKey(secretKey);
      setToken(token);
    },
    logout(state, action) {
      localStorage.removeItem("token");
      localStorage.removeItem("admin_");
      localStorage.removeItem("key");
      localStorage.removeItem("isAuth");
      state.admin = {};
      state.isAuth = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload && action.payload.statusCode === SuccessStatusCode) {
        const user = {
          adminId: action.payload.data.adminId,
          nickname: action.payload.data.adminId,
          email: action.payload.data.adminId,
          adminType: action.payload.data.adminId,
          permissions: []
        };
        let token_ = jwt_decode(action.payload.data.accessToken);
        state.flag = action.payload.flag;
        state.admin = user;
        state.isAuth = true;
        setToken(action.payload.data.accessToken);
        localStorage.setItem("token", action.payload.data.accessToken);
        localStorage.setItem("refreshToken", action.payload.data.refreshToken);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("isAuth", true);
        Success("Login successfully");
      } else {
        DangerRight(action?.payload?.message);
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(signUp.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(signUp.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        Success("Admin Create Successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateCode.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateCode.fulfilled, (state, action) => {
      state.isLoading = false;
      Success("Code Update Successfully");
      setTimeout(() => {
        window.location.href = "/";
      }, 10);
    });
    builder.addCase(updateCode.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.admin = action.payload?.data;
    });

    builder.addCase(getAdmin.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateAdmin.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.admin = {
        ...state.admin,
        name: action.payload?.admin?.name,
        email: action.payload?.admin?.email,
        image: action.payload?.admin?.image
      };
      state.isLoading = false;
      Success("Admin Updated Successfully");
    });

    builder.addCase(updateAdmin.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateAdminPassword.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateAdminPassword.fulfilled, (state, action) => {
      debugger;
      state.isLoading = false;
      state.admin = action?.payload?.admin;
      window.localStorage.clear();
      window.sessionStorage.clear();
      setToken();
      SetDevKey();
      state.admin = {};
      state.isAuth = false;

      window.location.href = "/login";
      Success("Admin Updated Successfully");
    });

    builder.addCase(updateAdminPassword.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});
export default authSlice.reducer;
export const { setOldAdmin, logout } = authSlice.actions;
