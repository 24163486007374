/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../component/api/axiosApi";
import { DangerRight, Success } from "../../component/api/toastServices";
import { SuccessStatusCode } from "../../util/constants";

const initialState = {
  isLoading: false,
  isSkeleton: false,
  hashTag: [],
  total: null
};

export const getAllHashtag = createAsyncThunk(
  "admin/hashtag/getAll",
  async () => {
    return apiInstance.get(`admin/hashtags`);
  }
);

export const addHashtag = createAsyncThunk(
  "admin/hashtag/create",
  async (payload) => {
    return apiInstance.post(`admin/hashtags`, payload);
  }
);

export const updateHashTag = createAsyncThunk(
  "admin/hashtag/update",
  async (payload) => {
    return apiInstance.put(`admin/hashtags/${payload.id}`, payload.body);
  }
);

export const bulkUpdateHashTag = createAsyncThunk(
  "admin/hashtag/bulkUpdate",
  async (payload) => {
    return apiInstance.put(`admin/hashtags/bulk-update`, payload);
  }
);

export const bulkUpdateHashTagCategory = createAsyncThunk(
  "admin/hashtagCategory/bulkUpdate",
  async (payload) => {
    return apiInstance.put(`admin/hashtag/category/bulk-update`, payload);
  }
);

export const deleteHashTag = createAsyncThunk(
  "admin/hashtag/delete",
  async (id) => {
    const res = await apiInstance.delete(`admin/hashtags/${id}`);
    return {
      data: res,
      id
    };
  }
);

const hashTagSlice = createSlice({
  name: "hashTagSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllHashtag.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllHashtag.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.hashTag = action.payload.data;
    });

    builder.addCase(getAllHashtag.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(addHashtag.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addHashtag.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        Success("HashTag Added Successfully");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(addHashtag.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateHashTag.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateHashTag.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        Success("HashTag Updated Successfully");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(updateHashTag.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(bulkUpdateHashTag.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(bulkUpdateHashTag.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        Success("HashTag Updated Successfully");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(bulkUpdateHashTag.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(bulkUpdateHashTagCategory.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(bulkUpdateHashTagCategory.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        Success("HashTag Updated Successfully");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(bulkUpdateHashTagCategory.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteHashTag.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteHashTag.fulfilled, (state, action) => {
      if (action?.payload?.data?.statusCode === SuccessStatusCode) {
        state.hashTag = state.hashTag.filter(
          (item) => item?.hashTagId !== action?.payload?.id
        );

        state.total -= 1;
        Success("HashTag Delete Successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(deleteHashTag.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});
export default hashTagSlice.reducer;
