import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../component/api/axiosApi";
import { Success } from "../../component/api/toastServices";
import { genQueryString } from "../../util/fuction";

const initialState = {
  user: [],
  blackList: [],
  userProfile: {},
  userRequest: {},
  booking: [],
  isLoading: false,
  isSkeleton: false,
  total: null,
  history: [],
  bartenderApplycation: [],
  bartenderEditHistory: [],
  totalEditHistory: null
};

export const allUsers = createAsyncThunk(
  "admin/user/getAll",
  async (payload) => {
    return apiInstance.get(genQueryString("admin/user", payload));
  }
);

export const getAllUsers = createAsyncThunk(
  "admin/user/getAllUsers",
  async (payload) => {
    return apiInstance.get(genQueryString("admin/user", payload));
  }
);

export const getAllUsersByPhone = createAsyncThunk(
  "admin/user/getAllUsersByPhone",
  async (payload) => {
    return apiInstance.post("admin/user/search-phones", payload);
  }
);

export const getBlackList = createAsyncThunk(
  "admin/blackList/getAll",
  async (payload) => {
    return apiInstance.get(genQueryString("admin/user/blacklist", payload));
  }
);

export const getBatendnerApplication = createAsyncThunk(
  "admin/batendnerApplication/getAll",
  async (payload) => {
    return apiInstance.get(
      genQueryString("admin/user/bartender/application", payload)
    );
  }
);

export const getBatendnerEditHistory = createAsyncThunk(
  "admin/batendnerEditHistory/getAll",
  async (payload) => {
    return apiInstance.get(
      genQueryString("admin/user/bartender/modification", payload)
    );
  }
);

export const getUser = createAsyncThunk("admin/user/profile", async (id) => {
  return apiInstance.get(`admin/user/${id}`);
});

export const getRequest = createAsyncThunk(
  "admin/user/getRequest",
  async (id) => {
    return apiInstance.get(`admin/user/bartender/request/${id}`);
  }
);

export const blockUser = createAsyncThunk(
  "admin/user/blockUser",
  async (id) => {
    return apiInstance.put(`admin/user/suspend/${id}`);
  }
);

export const unBlockUser = createAsyncThunk(
  "admin/user/unBlockUser",
  async (id) => {
    return apiInstance.put(`admin/user/unsuspend/${id}`);
  }
);

export const updateBankAccount = createAsyncThunk(
  "admin/user/updateBankAccount",
  async (payload) => {
    return apiInstance.put(
      `admin/user/bank-account/${payload.id}`,
      payload.body
    );
  }
);

export const approveBartenderRequest = createAsyncThunk(
  "admin/user/approveBartenderRequest",
  async (id) => {
    return apiInstance.put(`admin/user/bartender/request/${id}/approve`);
  }
);

export const rejectBartenderRequest = createAsyncThunk(
  "admin/user/rejectBartenderRequest",
  async (id) => {
    return apiInstance.put(`admin/user/bartender/request/${id}/reject`);
  }
);

export const getUserBookings = createAsyncThunk(
  "admin/booking/getUserBookings",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/booking/getUserBookings?userId=${payload?.userId}&start=${payload?.start}&limit=${payload?.limit}&type=${payload?.type}&startDate=${payload?.startDate}&endDate=${payload?.endDate}`
    );
  }
);

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allUsers.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(allUsers.fulfilled, (state, action) => {
      state.user = action?.payload?.data;
      state.total = action?.payload?.total;
      state.isSkeleton = false;
    });

    builder.addCase(allUsers.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getBlackList.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getBlackList.fulfilled, (state, action) => {
      state.blackList = action?.payload?.data;
      state.total = action?.payload?.total;
      state.isSkeleton = false;
    });

    builder.addCase(getBlackList.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getBatendnerApplication.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getBatendnerApplication.fulfilled, (state, action) => {
      state.batendnerApplication = action?.payload?.data;
      state.total = action?.payload?.total;
      state.isSkeleton = false;
    });

    builder.addCase(getBatendnerApplication.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getBatendnerEditHistory.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getBatendnerEditHistory.fulfilled, (state, action) => {
      state.bartenderEditHistory = action?.payload?.data || [];
      state.totalEditHistory = action?.payload?.total;
      state.isSkeleton = false;
    });

    builder.addCase(getBatendnerEditHistory.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getUser.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getUser.fulfilled, (state, action) => {
      state.userProfile = action?.payload?.data;
      state.isLoading = false;
    });

    builder.addCase(getUser.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getRequest.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getRequest.fulfilled, (state, action) => {
      state.userRequest = action?.payload?.data;
      state.isLoading = false;
    });

    builder.addCase(getRequest.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(blockUser.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(blockUser.fulfilled, (state, action) => {
      if (action.payload?.data?.data) {
        Success("Updated Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(blockUser.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(unBlockUser.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(unBlockUser.fulfilled, (state, action) => {
      if (action.payload?.data?.data) {
        Success("Updated Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(unBlockUser.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateBankAccount.pending, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateBankAccount.fulfilled, (state, action) => {
      if (action.payload?.data?.data) {
        Success("Updated Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(updateBankAccount.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(approveBartenderRequest.pending, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(approveBartenderRequest.fulfilled, (state, action) => {
      if (action.payload?.data?.data) {
        Success("Approved Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(approveBartenderRequest.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(rejectBartenderRequest.pending, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(rejectBartenderRequest.fulfilled, (state, action) => {
      if (action.payload?.data?.data) {
        Success("Rejected Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(rejectBartenderRequest.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getUserBookings.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getUserBookings.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.booking = action.payload.services;
      state.total = action.payload.total;
    });

    builder.addCase(getUserBookings.rejected, (state, action) => {
      state.isSkeleton = false;
    });
  }
});
export default userSlice.reducer;
