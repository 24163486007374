/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../component/api/axiosApi";
import { DangerRight, Success } from "../../component/api/toastServices";
import { SuccessStatusCode } from "../../util/constants";
import { genQueryString } from "../../util/fuction";

const initialState = {
  isLoading: false,
  isSkeleton: false,
  coupon: [],
  total: null
};

export const getAllCoupon = createAsyncThunk(
  "admin/coupon/getAll",
  async (payload) => {
    return apiInstance.get(genQueryString("admin/coupons", payload));
  }
);

export const addCoupon = createAsyncThunk(
  "admin/coupon/create",
  async (payload) => {
    return apiInstance.post(`admin/coupons`, payload);
  }
);

export const updateCoupon = createAsyncThunk(
  "admin/coupon/update",
  async (payload) => {
    return apiInstance.put(`admin/coupons/${payload.id}`, payload.body);
  }
);

export const issueCoupon = createAsyncThunk(
  "admin/coupon/issue",
  async (payload) => {
    return apiInstance.post(
      `admin/coupons/${payload.id}/issuance`,
      payload.body
    );
  }
);

export const deleteCoupon = createAsyncThunk(
  "admin/coupon/delete",
  async (id) => {
    const res = await apiInstance.delete(`admin/coupons/${id}`);
    return {
      data: res,
      id
    };
  }
);

const couponSlice = createSlice({
  name: "couponSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCoupon.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllCoupon.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.coupon = action.payload.data;
      state.total = action.payload.count;
    });

    builder.addCase(getAllCoupon.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(addCoupon.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addCoupon.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        state.total += 1;
        Success("Coupon Added Successfully");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(addCoupon.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateCoupon.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateCoupon.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        Success("Coupon Updated Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(updateCoupon.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(issueCoupon.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(issueCoupon.fulfilled, (state, action) => {
      if (action?.payload?.data?.data) {
        Success("Coupon Issued Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(issueCoupon.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteCoupon.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCoupon.fulfilled, (state, action) => {
      if (action?.payload?.data?.statusCode === SuccessStatusCode) {
        state.coupon = state.coupon.filter(
          (item) => item?.couponId !== action?.payload?.id
        );

        state.total -= 1;
        Success("Coupon Delete Successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(deleteCoupon.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});
export default couponSlice.reducer;
