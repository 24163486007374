import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Postcode from "react-daum-postcode";

const AddressModal = ({ handleSelectAddress }) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [mongoId, setMongoId] = useState("");

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData);
    }
  }, [dialogueData]);

  const handlePostcodeComplete = (data) => {
    if (window.kakao && window.kakao.maps) {
      window.kakao.maps.load(function () {
        const geocoder = new window.kakao.maps.services.Geocoder();
        geocoder.addressSearch(data.address, (result, status) => {
          if (status === window.kakao.maps.services.Status.OK) {
            const lat = result[0].y;
            const lng = result[0].x;
            handleSelectAddress(data.address, lat, lng);
            dispatch(closeDialog());
          }
        });
      });
    } else {
      handleSelectAddress(data.address, "", "");
      dispatch(closeDialog());
    }
  };

  return (
    <div className="dialog">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-md-4 col-11">
            <div class="mainDiaogBox position-relative">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">우편번호 검색</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <Postcode
                  style={{
                    width: "100%",
                    height: 500
                  }}
                  onComplete={handlePostcodeComplete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddressModal;
