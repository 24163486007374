import React, { useEffect, useRef, useState } from "react";
import { ExInput, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import moment from "moment";
import { SuccessStatusCode } from "../../../util/constants";
import {
  rejectComplain,
  solveComplain
} from "../../../redux/slice/complainSlice";

const mapStatusToKo = {
  pending: "미승인",
  rejected: "거절",
  approved: "승인",
  completed: "완료"
};

const ComplainDialog = (props) => {
  const dispatch = useDispatch();
  const loadingRef = useRef(false);
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [data, setData] = useState(null);
  const [handleType, setHandleType] = useState("completed");

  useEffect(() => {
    if (dialogueData) {
      setData(dialogueData);
      setHandleType(
        dialogueData?.objection?.processAt && dialogueData?.status
          ? dialogueData?.status
          : "pending"
      );
    }
  }, [dialogueData]);

  const handleSubmit = async () => {
    try {
      const body = {
        id: dialogueData?.objection?.id
      };
      let res = null;
      if (handleType === "completed") {
        res = await dispatch(solveComplain(body));
      } else {
        res = await dispatch(rejectComplain(body));
      }
      if (res.payload?.statusCode === SuccessStatusCode) {
        setData(null);
        dispatch(closeDialog());
        props.refetch && props.refetch();
      }
    } catch (err) {
      loadingRef.current = false;
      console.log("err.message", err);
    }
    loadingRef.current = false;
  };

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-4 col-md-6 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">이의 상세</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"신청자"}
                    value={data?.applicant?.nickname}
                    readOnly={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    label={"이의신청 날짜"}
                    value={
                      data?.objection?.createdAt
                        ? moment(data.objection.createdAt).format("YYYY-MM-DD")
                        : "-"
                    }
                    readOnly={true}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    label={"이의 처리 날짜"}
                    value={
                      data?.objection?.processAt
                        ? moment(data.objection.processAt).format("YYYY-MM-DD")
                        : "-"
                    }
                    readOnly={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    label={"정산 처리 날짜"}
                    value={
                      data?.processingDate
                        ? moment(data.processingDate).format("YYYY-MM-DD")
                        : "-"
                    }
                    readOnly={true}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    label={"처리 결과"}
                    value={data?.status ? mapStatusToKo[data.status] : ""}
                    readOnly={true}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    label={"정산 대상 금액"}
                    value={data?.settlementTargetAmount}
                    readOnly={true}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    label={"실제 입금액"}
                    value={data?.settlementAmount}
                    readOnly={true}
                  />
                </div>
                <div className="col-12">
                  <Textarea
                    value={data?.objection?.reason || ""}
                    label={`이의 제기 메세지`}
                    readOnly={true}
                  />
                </div>
              </div>

              <div className="inputData">
                <label>이의 처리</label>
                <div className="d-flex justify-content-start align-items-center gap-2">
                  <Button
                    className={`text-black fw-bold border`}
                    text={`정산 완료`}
                    type={`button`}
                    style={{
                      backgroundColor:
                        handleType === "completed" ? "#ecc246c7" : "#fff"
                    }}
                    onClick={() => setHandleType("completed")}
                  />
                  <Button
                    type={`submit`}
                    className={`text-black m10-left border fw-bold`}
                    style={{
                      backgroundColor:
                        handleType === "rejected" ? "#ecc246c7" : "#fff"
                    }}
                    text={`이의 거절`}
                    onClick={() => setHandleType("rejected")}
                  />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-end align-items-center gap-2">
                <Button
                  className={`bg-gray text-light`}
                  text={`취소`}
                  type={`button`}
                  onClick={() => dispatch(closeDialog())}
                />
                <Button
                  type={`submit`}
                  className={` text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`저장`}
                  onClick={() => handleSubmit()}
                  disabled={loadingRef.current}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplainDialog;
