/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
// import dayjs from "dayjs";
import $ from "jquery";
// import "moment/locale/ko"; // Import Korean locale

// moment.locale("ko"); // Set default locale to Korean

const DatePeriod = (props) => {
  const {
    analyticsStartDate,
    analyticsStartEnd,
    analyticsStartDateSet,
    direction,
    analyticsStartEndSet,
    analyticsDateSet,
    allAllow
  } = props;

  const handleApply = (event, picker) => {
    let start = moment(picker.startDate).format("YYYY-MM-DD");
    let end = moment(picker.endDate).format("YYYY-MM-DD");
    if (picker.chosenLabel === "ALL") {
      start = "ALL";
      end = "ALL";
    }
    analyticsDateSet && analyticsDateSet(start, end);
    analyticsStartDateSet && analyticsStartDateSet(start);
    analyticsStartEndSet && analyticsStartEndSet(end);
  };
  const [isDateRangePickerVisible, setDateRangePickerVisible] = useState(false);
  const [state, setState] = useState({
    start: moment().subtract(29, "days").startOf("day"),
    end: moment().endOf("day")
  });
  const { start, end } = state;

  const handleCancel = (event, picker) => {
    picker?.element.val("");
    analyticsStartDateSet("");
    analyticsStartEndSet("");
  };

  const handleCallback = (start, end) => {
    console.log("Start Date:", start, "End Date:", end);
    setState({
      start: moment(start).isValid() ? moment(start) : state.start,
      end: moment(end).isValid() ? moment(end) : state.end
    });
  };
  const label = start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY");

  const { color, bgColor } = props;

  const startAllDate = "1970-01-01";
  const endAllDate = moment().format("YYYY-MM-DD");

  $(document).ready(function () {
    $("data-range-key").removeClass("active");
  });

  const handleInputClick = () => {
    setDateRangePickerVisible(!isDateRangePickerVisible);
  };

  return (
    <div
      className="d-flex"
      style={{ width: "285px", height: "40px", justifyContent: direction }}
    >
      <DateRangePicker
        initialSettings={{
          startDate: moment(state.start).isValid()
            ? state.start.format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          endDate: moment(state.end).isValid()
            ? state.end.format("YYYY-MM-DD")
            : moment().add(1, "days").format("YYYY-MM-DD"),
          locale: {
            format: "YYYY-MM-DD",
            applyLabel: "적용",
            cancelLabel: "취소",
            minYear: 1901,
            maxYear: 2099,
            daysOfWeek: ["일", "월", "화", "수", "목", "금", "토"], // Days in Korean
            monthNames: [
              "1월",
              "2월",
              "3월",
              "4월",
              "5월",
              "6월",
              "7월",
              "8월",
              "9월",
              "10월",
              "11월",
              "12월"
            ]
          }
        }}
        onCallback={handleCallback}
        onApply={handleApply}
      >
        <input
          type="text"
          bgColor={bgColor}
          color={color}
          readOnly
          onClick={handleInputClick}
          className={`daterange float-right  mr-4  text-center ${bgColor} ${color}`}
          value={
            (analyticsStartDate === startAllDate &&
              analyticsStartEnd === endAllDate) ||
            (analyticsStartDate === "ALL" && analyticsStartEnd === "ALL")
              ? "날짜 선택"
              : moment(analyticsStartDate).isValid() &&
                moment(analyticsStartEnd).isValid()
              ? `${moment(analyticsStartDate).format("YYYY-MM-DD")} To ${moment(
                  analyticsStartEnd
                ).format("YYYY-MM-DD")}`
              : "날짜 선택"
          }
          style={{
            width: "85%",
            fontWeight: 600,
            cursor: "pointer",
            border: "1px solid black",
            display: "flex",
            justifyContent: "center",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "6px",
            height: "40px !important",
            color: "#000"
          }}
        />
      </DateRangePicker>
    </div>
  );
};

export default DatePeriod;
