import React, { useEffect, useState } from "react";
import Button from "../../extras/Button";
import Title from "../../extras/Title";
import { ExInput } from "../../extras/Input";
import ToggleSwitch from "../../extras/ToggleSwitch";
import ReactDatePicker from "react-datepicker";
import { CouponList } from "./CouponList";
import { getSetting, updateSetting } from "../../../redux/slice/settingSlice";
import { useDispatch, useSelector } from "react-redux";
import { isNumber } from "../../../util/fuction";
import { useRef } from "react";

export default function CouponSetting() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const loadingRef = useRef(false);

  const { setting } = useSelector((state) => state.setting);

  const dispatch = useDispatch();
  const [minimumPointsReservation, setMinimumPointsReservation] = useState("");
  const [minimumPointsSponsorship, setMinimumPointsSponsorship] = useState("");
  const [minimumPointsUsableSponsorship, setMinimumPointsUsableSponsorship] =
    useState("");
  const [minimumPointsUsableReservation, setMinimumPointsUsableReservation] =
    useState("");
  const [couponDiscountRate, setCouponDiscountRate] = useState("");
  const [enableCouponIssuanceSignup, setEnableCouponIssuanceSignup] =
    useState(false);
  const [enableCouponIssuanceReview, setEnableCouponIssuanceReview] =
    useState(false);

  const [error, setError] = useState({
    minimumPointsReservation: "",
    minimumPointsSponsorship: "",
    minimumPointsUsableSponsorship: "",
    minimumPointsUsableReservation: "",
    couponDiscountRate: ""
  });

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  useEffect(() => {
    if (setting) {
      setMinimumPointsReservation(setting.minimumPointsReservation);
      setMinimumPointsSponsorship(setting.minimumPointsSponsorship);
      setMinimumPointsUsableSponsorship(setting.minimumPointsUsableSponsorship);
      setMinimumPointsUsableReservation(setting.minimumPointsUsableReservation);
      setEnableCouponIssuanceSignup(setting.enableCouponIssuanceSignup);
      setEnableCouponIssuanceReview(setting.enableCouponIssuanceReview);
      setStartDate(
        setting.couponStartAt ? new Date(setting.couponStartAt) : ""
      );
      setEndDate(setting.couponEndAt ? new Date(setting.couponEndAt) : "");
      setCouponDiscountRate(setting.couponDiscountRate);
    }
  }, [setting]);

  const handleStartDateChange = (selectedDate) => {
    setStartDate(selectedDate);
  };
  const handleEndDateChange = (selectedDate) => {
    setEndDate(selectedDate);
  };

  const handleSetError = (field, value) => {
    let newError = {};
    if (field === "minimumPointsReservation") {
      newError.minimumPointsReservation = !value
        ? "Minimum Points Reservation is required"
        : "";
    }
    if (field === "minimumPointsSponsorship") {
      newError.minimumPointsSponsorship = !value
        ? "Minimum Points Sponsorship is required"
        : "";
    }
    if (field === "minimumPointsUsableSponsorship") {
      newError.minimumPointsUsableSponsorship = !value
        ? "Minimum Points Usable Sponsorship is required"
        : "";
    }
    if (field === "minimumPointsUsableReservation") {
      newError.minimumPointsUsableReservation = !value
        ? "Minimum Points Usable Reservation is required"
        : "";
    }
    if (field === "couponDiscountRate") {
      newError.couponDiscountRate = !value
        ? "Coupon Discount Rate is required"
        : "";
    }
    setError((preState) => ({ ...error, ...newError }));
  };

  const onsubmit = async () => {
    loadingRef.current = true;
    if (
      !minimumPointsReservation ||
      !minimumPointsSponsorship ||
      !minimumPointsUsableSponsorship ||
      !minimumPointsUsableReservation ||
      !couponDiscountRate
    ) {
      let error = {};
      if (!minimumPointsReservation)
        error.minimumPointsReservation =
          "Minimum Points Reservation is required";
      if (!minimumPointsSponsorship)
        error.minimumPointsSponsorship =
          "Minimum Points Sponsorship is required";
      if (!minimumPointsUsableSponsorship)
        error.minimumPointsUsableSponsorship =
          "Minimum Points Usable Sponsorship is required";
      if (!minimumPointsUsableReservation)
        error.minimumPointsUsableReservation =
          "Minimum Points Usable Reservation is required";
      if (!couponDiscountRate)
        error.couponDiscountRate = "Coupon Discount Rate is required";

      loadingRef.current = false;
      return setError({ ...error });
    } else {
      const body = {
        minimumPointsUsableReservation: Number(minimumPointsUsableReservation),
        minimumPointsSponsorship: Number(minimumPointsSponsorship),
        minimumPointsUsableSponsorship: Number(minimumPointsUsableSponsorship),
        minimumPointsReservation: Number(minimumPointsReservation),
        couponDiscountRate: Number(couponDiscountRate),
        enableCouponIssuanceSignup,
        enableCouponIssuanceReview,
        couponStartAt: startDate,
        couponEndAt: endDate
      };
      try {
        const res = await dispatch(updateSetting(body));
        console.log("res", res);
        if (res?.payload?.data?.data) {
          loadingRef.current = false;
          dispatch(getSetting());
        }
      } catch (error) {
        loadingRef.current = false;
        console.log(error);
      }
    }
  };

  return (
    <div className="mainSetting">
      <Title name="포인트 사용 및 쿠폰 발급 설정" />
      <div className="settingBox">
        <div className="d-flex justify-content-end">
          <div className="formFooter">
            <Button
              type={`submit`}
              className={`text-light m10-left fw-bold`}
              text={`저장`}
              style={{ backgroundColor: "#1ebc1e" }}
              disabled={loadingRef.current}
              onClick={onsubmit}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 inputData">
            <label>예약 시 포인트 사용 설정</label>
            <div className="border rounded-2 w-100">
              <div className="border-bottom d-flex">
                <div className="bg-gray rounded-top-start-2 min-width-260 d-flex align-items-center justify-content-center p-2 fs-16">
                  예약 사용 시 보유 포인트 제한
                </div>
                <div className="p-2 d-flex align-items-center">
                  <ExInput
                    type={`text`}
                    label={``}
                    errorMessage={error.minimumPointsReservation}
                    value={minimumPointsReservation}
                    onChange={(e) => {
                      if (
                        (isNumber(e.target.value) && e.target.value) ||
                        !e.target.value
                      ) {
                        setMinimumPointsReservation(e.target.value);
                        handleSetError(
                          "minimumPointsReservation",
                          e.target.value
                        );
                      }
                    }}
                  />
                  <span className="ms-1 fs-15">
                    P 이상 보유한 경우 결제 시 사용 가능
                  </span>
                </div>
              </div>
              <div className="d-flex">
                <div className="bg-gray min-width-260 rounded-bottom-start-2 d-flex align-items-center justify-content-center p-2 fs-16">
                  예약 시 최소 사용 포인트 제한
                </div>
                <div className="p-2 d-flex align-items-center ">
                  <span className="me-1 fs-15">1회 결제 시 최소</span>
                  <ExInput
                    type={`text`}
                    label={``}
                    errorMessage={error.minimumPointsUsableReservation}
                    value={minimumPointsUsableReservation}
                    onChange={(e) => {
                      if (
                        (isNumber(e.target.value) && e.target.value) ||
                        !e.target.value
                      ) {
                        setMinimumPointsUsableReservation(e.target.value);
                        handleSetError(
                          "minimumPointsUsableReservation",
                          e.target.value
                        );
                      }
                    }}
                  />
                  <span className="ms-1 fs-15">P 이상 사용 가능</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 inputData">
            <label>후원 시 포인트 사용 설정</label>
            <div className="border rounded-2 w-100">
              <div className="border-bottom d-flex">
                <div className="bg-gray rounded-top-start-2 min-width-260 d-flex align-items-center justify-content-center p-2 fs-16">
                  후원 시 보유 포인트 제한
                </div>
                <div className="p-2 d-flex align-items-center">
                  <ExInput
                    type={`text`}
                    label={``}
                    errorMessage={error.minimumPointsSponsorship}
                    value={minimumPointsSponsorship}
                    onChange={(e) => {
                      if (
                        (isNumber(e.target.value) && e.target.value) ||
                        !e.target.value
                      ) {
                        setMinimumPointsSponsorship(e.target.value);
                        handleSetError(
                          "minimumPointsSponsorship",
                          e.target.value
                        );
                      }
                    }}
                  />
                  <span className="ms-1 fs-15">
                    P 이상 보유한 경우 후원 시 사용 가능
                  </span>
                </div>
              </div>
              <div className="d-flex">
                <div className="bg-gray min-width-260 rounded-bottom-start-2 d-flex align-items-center justify-content-center p-2 fs-16">
                  후원 시 최소 사용 포인트 제한
                </div>
                <div className="p-2 d-flex align-items-center ">
                  <span className="me-1 fs-15">1회 후원 시 최소</span>
                  <ExInput
                    type={`text`}
                    label={``}
                    errorMessage={error.minimumPointsUsableSponsorship}
                    value={minimumPointsUsableSponsorship}
                    onChange={(e) => {
                      if (
                        (isNumber(e.target.value) && e.target.value) ||
                        !e.target.value
                      ) {
                        setMinimumPointsUsableSponsorship(e.target.value);
                        handleSetError(
                          "minimumPointsUsableSponsorship",
                          e.target.value
                        );
                      }
                    }}
                  />
                  <span className="ms-1 fs-15">P 이상 사용 가능</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 inputData">
            <label>쿠폰 발급 설정</label>
            <div className="d-flex align-items-center mb-2">
              <div className="inputData d-flex align-items-center">
                <label className="me-2">최초 회원가입 시</label>
                <ToggleSwitch
                  onClick={() =>
                    setEnableCouponIssuanceSignup(!enableCouponIssuanceSignup)
                  }
                  value={enableCouponIssuanceSignup}
                />
              </div>
              <div className="inputData d-flex align-items-center ms-4">
                <label className="me-2">리뷰 작성 시</label>
                <ToggleSwitch
                  onClick={() =>
                    setEnableCouponIssuanceReview(!enableCouponIssuanceReview)
                  }
                  value={enableCouponIssuanceReview}
                />
              </div>
            </div>
            <div className="border rounded-2 w-100">
              <div className="d-flex">
                <div className="bg-gray rounded-start-2 min-width-260 d-flex align-items-center justify-content-center p-2 fs-16">
                  발급 쿠폰 설정
                </div>
                <div className="p-2 d-flex align-items-center">
                  <span className="fs-15 me-1 text-nowrap">할인율</span>
                  <ExInput
                    type={`text`}
                    label={``}
                    errorMessage={error.couponDiscountRate}
                    value={couponDiscountRate}
                    onChange={(e) => {
                      if (
                        (isNumber(e.target.value) && e.target.value) ||
                        !e.target.value
                      ) {
                        setCouponDiscountRate(e.target.value);
                        handleSetError("couponDiscountRate", e.target.value);
                      }
                    }}
                  />
                  <span className="ms-1 fs-15 text-nowrap me-3">%</span>
                  <span className="fs-15 me-1 text-nowrap">발급 기간</span>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="yyyy-MM-dd"
                    maxDate={endDate ? endDate : undefined}
                  />
                  <span className="ms-1 fs-15 text-nowrap me-1">부터</span>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat="yyyy-MM-dd"
                    minDate={startDate ? startDate : undefined}
                  />
                  <span className="ms-1 fs-15 text-nowrap">까지</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <CouponList />
      </div>
    </div>
  );
}
