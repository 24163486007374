import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confirm } from "../../../util/Alert";
import Title from "../../extras/Title";
import Pagination from "../../extras/Pagination";
import { getAllSalons, salonDelete } from "../../../redux/slice/salonSlice";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "./../../extras/Table";
import Button from "../../extras/Button";
import moment from "moment";
import AddAdminModal from "./AddAdminModal";
import { useCallback } from "react";
import { deleteAdmin, getAllAdmin } from "../../../redux/slice/adminSlice";

export const AdminList = () => {
  const [data, setData] = useState([]);

  const { admin, total } = useSelector((state) => state.admin);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const fetchData = useCallback(() => {
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage
    };
    dispatch(getAllAdmin(payload));
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setData(admin);
  }, [admin]);

  const mapTable = [
    {
      Header: "번호",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "이름",
      Cell: ({ row }) => <span>{row?.nickname}</span>
    },
    {
      Header: "ID(이메일)",
      Cell: ({ row }) => <span>{row?.email}</span>
    },
    {
      Header: "휴대폰 번호",
      Cell: ({ row }) => (
        <span className="truncate-line-2">{row?.phoneNumber}</span>
      )
    },
    {
      Header: "생성일",
      Cell: ({ row }) => (
        <span>
          {row?.createdAt && moment(row.createdAt).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: "관리자 구분",
      Cell: ({ row }) => (
        <span className="truncate-line-2">
          {row?.permissionCategory?.category}
        </span>
      )
    },
    {
      Header: "수정",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenEdit(row)}
        >
          수정
        </button>
      )
    },
    {
      Header: "삭제",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleDelete(row?.id)}
        >
          삭제
        </button>
      )
    }
  ];

  const handleDelete = async (id) => {
    try {
      const data = await confirm({
        title: "관리자 삭제하기",
        text: "해당 관리자 정보를 삭제하시겠습니까?",
        confirm: "확인",
        cancle: "취소"
      });
      const yes = data?.isConfirmed;
      console.log("yes", yes);
      if (yes) {
        dispatch(deleteAdmin(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenEdit = (row) => {
    dispatch(openDialog({ type: "addAdmin", data: row }));
  };

  const handleOpenAdd = () => {
    dispatch(openDialog({ type: "addAdmin", data: null }));
  };

  return (
    <div className="userTable">
      <Title name="관리자 리스트/등록" />
      <p className="fs-18">관리자 리스트</p>
      <div className="betBox mb-3">
        <div className="col-md-8 col-lg-7 inputData">
          <label>총 관리자 {total} 명</label>
        </div>
        <Button
          className={`bg-button-excel p-10 text-black font-bold m10-bottom ms-auto mt-auto`}
          text={`관리자 추가`}
          style={{ height: "40px" }}
          // bIcon={`fa-solid fa-user-plus`}
          onClick={() => handleOpenAdd()}
        />
      </div>
      <div>
        <Table
          data={data}
          mapData={mapTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"server"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
      {dialogue && dialogueType === "addAdmin" && (
        <AddAdminModal refetch={fetchData} />
      )}
    </div>
  );
};
