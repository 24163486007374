// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "../../extras/Button";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../extras/Title";
import { getSetting, updateSetting } from "../../../redux/slice/settingSlice";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import { Textarea } from "../../extras/Input";
import EditModal from "./EditModal";

const ChatSetting = (props) => {
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state.setting);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const [text, setText] = useState();

  const [error, setError] = useState({
    text: ""
  });

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  useEffect(() => {
    if (setting) {
      setText(setting.fixedMessage);
    }
  }, [setting]);

  const handleOpenEdit = () => {
    dispatch(openDialog({ type: "editChat", data: text }));
  };

  return (
    <div className="mainSetting">
      <Title name="채팅방 고정 메세지 설정" />
      <div className="settingBox">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="inputData">
                <label>고정 메시지 내용</label>
              </div>

              <Button
                type={`submit`}
                className={`text-white m10-left fw-bold`}
                text={`수정`}
                style={{ backgroundColor: "#1ebc1e", color: "#fff" }}
                onClick={handleOpenEdit}
              />
            </div>
            <Textarea value={text} label={``} row={20} disabled />
          </div>
        </div>
        {dialogue && dialogueType === "editChat" && <EditModal />}
      </div>
    </div>
  );
};
export default ChatSetting;
