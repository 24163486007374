/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-dupe-keys */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "../../extras/Button";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import { addProduct, updateProduct } from "../../../redux/slice/serviceSlice";
import { ExInput, SelectV2 } from "../../extras/Input";
import { isNumberOnly } from "../../../util/fuction";

const typeOptions = [
  {
    value: "WHISKY",
    name: "양주"
  },
  {
    value: "BEER",
    name: "맥주"
  },
  {
    value: "WINE",
    name: "와인"
  },
  {
    value: "CHAMPAGNE",
    name: "샴페인"
  },
  {
    value: "SIDE_DISH",
    name: "안주"
  },
  {
    value: "BEVERAGE",
    name: "음료"
  }
];

const AddStockModal = (props) => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [name, setName] = useState();
  const [purchasePrice, setPurchasePrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [type, setType] = useState("WHISKY");
  const [stock, setStock] = useState("");

  const [error, setError] = useState({
    name: "",
    purchasePrice: "",
    sellingPrice: "",
    stock: ""
  });

  useEffect(() => {
    if (dialogueData.data) {
      setName(dialogueData.data.name);
      setStock(dialogueData.data.stock || 0 + "");
      setType(dialogueData.data.category);
      setPurchasePrice(dialogueData.data.originalPrice || 0 + "");
      setSellingPrice(dialogueData.data.salePrice || 0 + "");
    }
  }, [dialogueData]);

  console.log("dialogueData", dialogueData);

  const handleSubmit = async () => {
    try {
      if (!name || !type || !stock || !purchasePrice || !sellingPrice) {
        let error = {};
        if (!name) error.name = "Name is Required";
        if (!type) error.type = "Type is Required";
        if (!stock) error.stock = "Stock is Required";
        if (!purchasePrice) error.purchasePrice = "Purchase Price is Required";
        if (!sellingPrice) error.sellingPrice = "Selling Price is Required";

        return setError({ ...error });
      } else {
        const body = {
          name: name,
          category: type,
          salePrice: Number(sellingPrice || 0),
          originalPrice: Number(purchasePrice || 0),
          stock: Number(stock || 0)
        };
        if (dialogueData.stockId) {
          let payload = {
            body: { ...body, storeId: dialogueData.storeId },
            id: dialogueData.stockId
          };
          dispatch(updateProduct(payload));
          dispatch(closeDialog());
          setTimeout(() => {
            props.refetch && props.refetch();
          }, 1000);
        } else {
          dispatch(addProduct({ ...body, storeId: dialogueData.storeId }));
          dispatch(closeDialog());
          setTimeout(() => {
            props.refetch && props.refetch();
          }, 1000);
        }
        // dispatch(closeDialog());
      }
    } catch (err) {
      console.log("err.message", err);
    }
  };

  return (
    <div className="dialog">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-6 col-md-6 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">재고 등록</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>

              <div className="row align-items-start formBody">
                {/* menu */}
                <div className="col-9">
                  <ExInput
                    type={`text`}
                    label={`이름`}
                    name={"name"}
                    value={name}
                    placeholder="이름을 입력하세요"
                    errorMessage={error?.name}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          name: ` Name is required`
                        });
                      } else {
                        return setError({
                          ...error,
                          name: ""
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-3">
                  <div className="row">
                    <div className="inputData col-12">
                      <label>분류</label>
                      <div
                        className="d-flex align-items-center"
                        style={{ marginTop: "4px" }}
                      >
                        <SelectV2
                          id={`hours`}
                          option={typeOptions}
                          className={`inputSelectTime`}
                          btnClass={`mt-0`}
                          value={type}
                          onChange={(value) => {
                            setType(value);
                            return setError({
                              ...error,
                              type: ""
                            });
                          }}
                          errorMessage={error.type}
                          angle={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="d-flex inputData">
                    <ExInput
                      type={`text`}
                      label={`구매 가격`}
                      value={purchasePrice}
                      errorMessage={error?.purchasePrice}
                      onChange={(e) => {
                        if (!isNumberOnly(e.target.value) && !e.target.value) {
                          return;
                        }
                        setPurchasePrice(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            purchasePrice: `Purchase Price is required`
                          });
                        } else {
                          return setError({
                            ...error,
                            purchasePrice: ""
                          });
                        }
                      }}
                    />
                    <span
                      className="px-1 text-nowrap"
                      style={{ marginTop: "40px" }}
                    >
                      원
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="d-flex inputData">
                    <ExInput
                      type={`text`}
                      label={`판매 가격`}
                      value={sellingPrice}
                      errorMessage={error?.sellingPrice}
                      onChange={(e) => {
                        if (!isNumberOnly(e.target.value) && !e.target.value) {
                          return;
                        }
                        setSellingPrice(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            sellingPrice: `Selling Price is required`
                          });
                        } else {
                          return setError({
                            ...error,
                            sellingPrice: ""
                          });
                        }
                      }}
                    />
                    <span
                      className="px-1 text-nowrap"
                      style={{ marginTop: "40px" }}
                    >
                      원
                    </span>
                  </div>
                </div>
                <div className="col-12 d-flex align-items-center inputData">
                  <ExInput
                    type={`text`}
                    label={`재고`}
                    value={stock}
                    className={"w-100"}
                    errorMessage={error?.stock}
                    onChange={(e) => {
                      if (!isNumberOnly(e.target.value) && !e.target.value) {
                        return;
                      }
                      setStock(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          stock: `Stock is required`
                        });
                      } else {
                        return setError({
                          ...error,
                          stock: ""
                        });
                      }
                    }}
                  />
                  <span
                    className="px-1 text-nowrap"
                    style={{ marginTop: "29px" }}
                  >
                    개
                  </span>
                </div>
              </div>
              <div className="row  formFooter mt-3">
                <div className="col-12 text-end m0">
                  <Button
                    className={`bg-gray text-light`}
                    text={`취소`}
                    type={`button`}
                    onClick={() => dispatch(closeDialog())}
                  />
                  <Button
                    type={`submit`}
                    className={`text-white m10-left`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`등록`}
                    onClick={() => handleSubmit()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddStockModal;
