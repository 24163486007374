import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../component/api/axiosApi";
import { Success } from "../../component/api/toastServices";
import { SuccessStatusCode } from "../../util/constants";
import { genQueryString } from "../../util/fuction";

const initialState = {
  salon: [],
  booking: [],
  bookingDetail: null,
  salonSchedule: [],
  bookingSettlementWeek: [],
  bookingSettlement: [],
  isLoading: false,
  total: 0,
  count: 0
};

export const getAllSalons = createAsyncThunk(
  "admin/stores",
  async (payload) => {
    return apiInstance.get(genQueryString("admin/stores", payload));
  }
);

export const getAllStoresList = createAsyncThunk(
  "admin/getAllStoresList",
  async (payload) => {
    return apiInstance.get(genQueryString("admin/stores/list", payload));
  }
);

export const getSalonDetail = createAsyncThunk(
  "admin/stores/getStore",
  async (id) => {
    return apiInstance.get(`admin/stores/${id}`);
  }
);
export const getSalonReview = createAsyncThunk(
  "admin/review/salonReviews",
  async (id) => {
    return apiInstanceFetch.get(`admin/review/salonReviews?salonId=${id}`);
  }
);
export const salonReviewDelete = createAsyncThunk(
  "admin/review/delete",
  async (payload) => {
    return apiInstance.delete(`admin/review/delete?reviewId=${payload}`);
  }
);

export const getSalonSchedule = createAsyncThunk(
  "admin/salon/salonTime",
  async (storeId) => {
    return apiInstance.get(`admin/operation-hours/${storeId}`);
  }
);

export const updateSalonTime = createAsyncThunk(
  "admin/salon/updateSalonTime",
  async (payload) => {
    return apiInstance.put(
      `admin/operation-hours/${payload?.id}`,
      payload?.data
    );
  }
);

export const addSalon = createAsyncThunk(
  "admin/stores/create",
  async (payload) => {
    return apiInstance.post(`admin/stores`, payload);
  }
);

export const updateSalon = createAsyncThunk(
  "admin/salon/update",
  async (payload) => {
    return apiInstance.put(
      `admin/stores/${payload?.storeId}`,
      payload?.formData
    );
  }
);

export const salonDelete = createAsyncThunk(
  "admin/salon/delete",
  async (payload) => {
    return apiInstance.delete(`admin/stores/${payload}`);
  }
);

export const activesalon = createAsyncThunk(
  "admin/salon/isActive",
  async (payload) => {
    const res = await apiInstance.put(
      `admin/stores/${payload?.storeId}`,
      payload?.formData
    );
    return {
      data: res,
      storeId: payload.storeId,
      isActive: payload.formData.isActive
    };
  }
);

export const getSalonBookings = createAsyncThunk(
  "admin/booking/getSalonBookings",
  async (payload) => {
    return apiInstance.get(genQueryString("admin/reservations", payload));
  }
);

export const getSalonBookingDetail = createAsyncThunk(
  "admin/booking/getSalonBookingDetail",
  async (id) => {
    return apiInstance.get(`admin/reservations/${id}`);
  }
);

export const getStoreBookingSettlementWeek = createAsyncThunk(
  "admin/booking/settlement/week",
  async (payload) => {
    return apiInstance.get(
      genQueryString("admin/reservations/settlement/week", payload)
    );
  }
);

export const getStoreBookingSettlement = createAsyncThunk(
  "admin/booking/settlement",
  async (payload) => {
    return apiInstance.get(
      genQueryString("admin/reservations/settlement", payload)
    );
  }
);

export const getSalonHistory = createAsyncThunk(
  "admin/settlement/particularSalon",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/settlement/particularSalon?salonId=${payload?.salonId}&startDate=${payload?.startDate}&endDate=${payload?.endDate}`
    );
  }
);

export const settlementApproveBluk = createAsyncThunk(
  "admin/approve-bluk/isActive",
  async (payload) => {
    return apiInstance.put(
      `admin/reservations/settlement/approve-bluk`,
      payload
    );
  }
);

export const settlementCompleteBluk = createAsyncThunk(
  "admin/complete-bluk/isActive",
  async (payload) => {
    return apiInstance.put(
      `admin/reservations/settlement/complete-bluk`,
      payload
    );
  }
);

const salonSlice = createSlice({
  name: "salonSlice",
  initialState,
  reducers: {},
  // GetAll salon
  extraReducers: (builder) => {
    builder.addCase(getAllSalons.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllSalons.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.salon = action?.payload?.data;
      state.count = action?.payload?.count;
    });

    builder.addCase(getAllSalons.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(addSalon.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addSalon.fulfilled, (state, action) => {
      if (action.payload.statusCode === SuccessStatusCode) {
        state.salon.unshift(action.payload?.data);
        state.total += 1;
        Success("Store Create Successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(addSalon.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateSalon.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateSalon.fulfilled, (state, action) => {
      if (action.payload.statusCode === SuccessStatusCode) {
        Success("Store Update Successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(updateSalon.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(salonDelete.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(salonDelete.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        state.total -= 1;
        Success("Store Delete Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(salonDelete.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getSalonSchedule.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getSalonSchedule.fulfilled, (state, action) => {
      console.log("action?.payload?.data", action?.payload?.data);
      state.isSkeleton = false;
      state.salonSchedule = action?.payload?.data;
    });

    builder.addCase(getSalonSchedule.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getStoreBookingSettlementWeek.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(
      getStoreBookingSettlementWeek.fulfilled,
      (state, action) => {
        state.isSkeleton = false;
        state.bookingSettlementWeek = action?.payload?.data;
        state.total = action?.payload?.count;
      }
    );

    builder.addCase(getStoreBookingSettlementWeek.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getStoreBookingSettlement.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getStoreBookingSettlement.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.bookingSettlement = action?.payload?.data;
      state.total = action?.payload?.count;
    });

    builder.addCase(getStoreBookingSettlement.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(updateSalonTime.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateSalonTime.fulfilled, (state, action) => {
      console.log("action.payload111", action.payload);
      if (action.payload.statusCode === SuccessStatusCode) {
        Success("Time Update Successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(updateSalonTime.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(activesalon.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(activesalon.fulfilled, (state, action) => {
      if (action.payload?.data?.data) {
        const { isActive, storeId } = action.payload;
        const salonIndx = state.salon.findIndex(
          (salon) => salon?.storeId === storeId
        );
        if (salonIndx !== -1) {
          state.salon[salonIndx] = {
            ...state.salon[salonIndx],
            isActive
          };
        }
        Success("Store Update Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(activesalon.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getSalonBookings.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getSalonBookings.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.booking = action?.payload?.data;
      state.total = action?.payload?.count;
    });

    builder.addCase(getSalonBookings.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getSalonBookingDetail.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getSalonBookingDetail.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.bookingDetail = action.payload.data;
    });

    builder.addCase(getSalonBookingDetail.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getSalonDetail.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getSalonDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.salonDetail = action?.payload?.data;
    });

    builder.addCase(getSalonDetail.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getSalonReview.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getSalonReview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.review = action?.payload?.data;
    });

    builder.addCase(getSalonReview.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(salonReviewDelete.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(salonReviewDelete.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.review = state.review?.filter(
          (review) => review?._id !== action?.meta?.arg
        );
      }
      state.isLoading = false;
    });

    builder.addCase(salonReviewDelete.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getSalonHistory.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getSalonHistory.fulfilled, (state, action) => {
      state.salary = action?.payload?.services;
      state.total = action?.payload?.total;
      state.isSkeleton = false;
    });

    builder.addCase(getSalonHistory.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(settlementApproveBluk.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(settlementApproveBluk.fulfilled, (state, action) => {
      console.log("action.payload111", action.payload);
      if (action.payload.statusCode === SuccessStatusCode) {
        Success("Approved Successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(settlementApproveBluk.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(settlementCompleteBluk.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(settlementCompleteBluk.fulfilled, (state, action) => {
      console.log("action.payload111", action.payload);
      if (action.payload.statusCode === SuccessStatusCode) {
        Success("Completed Successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(settlementCompleteBluk.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});
export default salonSlice.reducer;
